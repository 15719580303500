import React from "react";
import { TFunction } from "i18next";

import { GET_USER_BID_LIST_REQ_SEARCH_TYPE_V2 } from "@sellernote/_shared/src/api-interfaces/shipda-api/bid";
import {
  APP_CURRENT_LANGUAGE,
  APP_REGION,
} from "@sellernote/_shared/src/constants";
import {
  Country,
  ExporterInfo,
  FreightType,
  InLand,
  InlnadTransportType,
  Port,
  SellernoteAppRegion,
  WareHouse,
} from "@sellernote/_shared/src/types/common/common";
import {
  AllBidStatus,
  BidContainerInfo,
  BidEventsMessageType,
  FormDestinationInlandMap,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BidEventMessageType,
  BidForwarderDetail,
  BidProductInfo,
  BidServiceType,
  CheckItemResult,
  ShipScheduleStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BidProjectStatus,
  BidStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { RealtimePortType } from "@sellernote/_shared/src/types/forwarding/bid";
import { DashboardBidItem } from "@sellernote/_shared/src/types/forwarding/dashboard";
import { SearchValueList } from "@sellernote/_shared/src/types/fulfillment/search";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { checkEqualObject } from "@sellernote/_shared/src/utils/common/etc";
import { validateValueLength } from "@sellernote/_shared/src/utils/common/validation";
import { StateBadgeForForwardingProps } from "@sellernote/_sds-v2/src/components/stateBadge/StateBadgeForForwarding";
import { FBA } from "@sellernote/common-interfaces";
import { ServiceType } from "@sellernote/common-interfaces/dist/bid";

import { validateEmail } from "../pageContainers/mypage/partner/utils/validation";
import { i18n, isLocalCountry, Trans } from "../utils/i18n";

import { BidSearchQuery } from "../jotaiStates/forwarding/bid/selectors";
import { formatWareHouse } from "./fba";

interface TransportSchedule {
  startDate: string;
  endDate: string;
  startPortCode: string;
  endPortCode: string;
}

type BidFilterType = "search" | "filter" | "date";

type BidScheduleSortOptionType =
  | "departureAndAsc"
  | "departureAndDesc"
  | "arrivalAndAsc"
  | "arrivalAndDesc";

/**
 * 위험물 여부 갯수 구하기
 * @param bidInfo Bid 정보
 */
const checkIsDangerous = (
  bidInfo: BidForwarderDetail
): CheckItemResult | undefined => {
  if (!bidInfo) {
    return undefined;
  }

  let sum = 0;
  let total = 0;
  if (bidInfo.containersInfo && bidInfo.containersInfo.length > 0) {
    total = bidInfo.containersInfo.length;
    sum = bidInfo.containersInfo.reduce(
      (acc: number, cur: BidContainerInfo) => {
        if (cur.isDangerous) {
          return acc + 1;
        }
        return acc;
      },
      0
    );
  } else {
    total = bidInfo.productsInfo.length;
    sum = bidInfo.productsInfo.reduce((acc: number, cur: BidProductInfo) => {
      if (cur.isDangerous) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }

  if (sum === total) {
    return 2; // 전부 해당
  } else if (sum === 0) {
    return 0; // 해당사항 없음
  }

  return 1; // 일부 해당
};

/**
 * 2단적재 가능 여부 체크
 * @param bidInfo Bidding Info
 */
const checkCanStack = (
  bidInfo: BidForwarderDetail
): CheckItemResult | undefined => {
  if (!bidInfo) {
    return undefined;
  }

  const { freightType, containersInfo, productsInfo } = bidInfo;

  if (freightType === "FCL") {
    const numberOfCanStack = containersInfo.filter(
      ({ canStack }) => canStack
    ).length;

    const containerQty = containersInfo.length;

    return numberOfCanStack === containerQty ? 2 : numberOfCanStack ? 1 : 0;
  } else if (freightType === "LCL") {
    const numberOfCanStack = productsInfo.filter(
      ({ isDouble }) => isDouble
    ).length;

    const productsQty = productsInfo.length;

    return numberOfCanStack === productsQty ? 2 : numberOfCanStack ? 1 : 0;
  }
};

function getBidStatusString(
  serviceType: BidServiceType,
  status: "all" | BidStatus,
  projectStatus?: BidProjectStatus
) {
  switch (status) {
    case "all":
      return <Trans i18nKey="mypage:bid.statusAll.title" />;
    case "committed":
      return <Trans i18nKey="mypage:bid.statusCommitted.title" />;
    case "estimating":
      return <Trans i18nKey="mypage:bid.statusEstimating.title" />;
    case "waiting":
      return <Trans i18nKey="mypage:bid.statusWaiting.title" />;
    case "scheduling":
      return <Trans i18nKey="mypage:bid.statusScheduling.title" />;
    case "waitingForExporterInfo":
      return <Trans i18nKey="mypage:bid.statusExporterInfo.title" />;
    case "inProgress":
      if (serviceType === ServiceType.FBA) {
        if (projectStatus === "scheduling") {
          return <Trans i18nKey="mypage:bid.statusScheduling.title" />;
        }
      }

      return <Trans i18nKey="mypage:bid.statusInProgress.title" />;
    case "finished":
      if (projectStatus === "failed") {
        return <Trans i18nKey="mypage:common.deny" />;
      }
      if (projectStatus === "canceled") {
        return <Trans i18nKey="mypage:common.canceled" />;
      }
      if (projectStatus === "expired") {
        return <Trans i18nKey="mypage:common.expired" />;
      }
      return <Trans i18nKey="mypage:bid.statusFinished.title" />;
    default:
      return "";
  }
}

function getBidProjectStatusString(
  serviceType: ServiceType,
  projectStatus?: BidProjectStatus | BidEventMessageType
) {
  if (serviceType === ServiceType.FBA) {
    switch (projectStatus) {
      case "scheduling":
        return "수출서류 요청";
      case "moving":
        return "운송 중";
      case "delivering":
        return "내륙 운송 중";
      case "finished":
        return "운송 완료";
      case "canceled":
        return "취소";
      case "failed":
      default:
        return "";
    }
  } else {
    switch (projectStatus) {
      case "contactingPartner":
        return <Trans i18nKey="mypage:bid.projectStatus.contactingPartner" />;
      case "scheduling":
        return <Trans i18nKey="mypage:bid.projectStatus.scheduling" />;
      case "moving":
        return <Trans i18nKey="mypage:bid.projectStatus.moving" />;
      case "portEntryAndPrepareCustoms":
        return (
          <Trans i18nKey="mypage:bid.projectStatus.portEntryAndPrepareCustoms" />
        );
      case "payment":
        return <Trans i18nKey="mypage:bid.projectStatus.payment" />;
      case "completeCustoms":
        return <Trans i18nKey="mypage:bid.projectStatus.completeCustoms" />;
      case "delivering":
        return <Trans i18nKey="mypage:bid.projectStatus.delivering" />;
      case "finished":
        return <Trans i18nKey="mypage:bid.projectStatus.finished" />;
      case "canceled":
        return <Trans i18nKey="mypage:bid.projectStatus.canceled" />;
      case "failed":
        return <Trans i18nKey="mypage:bid.projectStatus.failed" />;
      default:
        return "";
    }
  }
}

/**
 * projectStatus 에 추가된 status 를 문자열로 변환
 */
function getBidProjectStatusStringV2({
  projectStatus,
  t,
}: {
  projectStatus?: BidProjectStatus;
  t: TFunction<"dashboard">;
}) {
  switch (projectStatus) {
    case "beforeContactPartner":
      return t("dashboard:수입운송리스트_공통_상태_수출자컨택전");
    case "contactingPartner":
      return t("dashboard:수입운송리스트_공통_상태_수출자컨택중");
    case "waitingFreight":
      return "화물 준비 중";
    case "scheduling":
      return t("dashboard:수입운송리스트_공통_상태_선적스케줄안내");
    case "containerCarryOut":
      return t("dashboard:수입운송리스트_공통_상태_현지공컨테이너반출");
    case "containerPickup":
      return t("dashboard:수입운송리스트_공통_상태_현지화물픽업완료");
    case "gateIn":
      return t("dashboard:수입운송리스트_공통_상태_현지터미널반입완료");
    case "loaded":
      return t("dashboard:수입운송리스트_공통_상태_선적완료");
    case "moving":
      return t("dashboard:수입운송리스트_공통_상태_출항완료");
    case "portEntryAndPrepareCustoms":
      return t("dashboard:수입운송리스트_공통_상태_입항완료및통관준비");
    case "payment":
      return t("dashboard:수입운송리스트_공통_상태_정산요청");
    case "completeCustoms":
      return t("dashboard:수입운송리스트_공통_상태_통관완료");
    case "delivering":
      return t("dashboard:수입운송리스트_공통_상태_내륙운송중");
    case "settlementRejected":
    case "settlementConfirmed":
    case "settlementSecondConfirmed":
    case "settlementComplete":
    case "finished":
      return t("dashboard:수입운송리스트_공통_상태_운송완료");
    case "canceled":
      return t("dashboard:수입운송리스트_공통_상태_취소");
    case "failed":
      return t("dashboard:수입운송리스트_공통_상태_거절");
    default:
      return "";
  }
}

function getBidStateBadgeProps({
  status,
  projectStatus,
  isNewLine,
}: {
  status?: "all" | BidStatus;
  projectStatus?: BidProjectStatus;
  isNewLine?: boolean;
}): Omit<StateBadgeForForwardingProps, "type" | "size"> {
  switch (true) {
    case status === "committed":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_의뢰확인중" />,
      };
    case status === "estimating":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_견적산출중" />,
      };
    case status === "waiting":
    case status === "waitingForExporterInfo":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_진행결정요청" />,
      };

    case status === "inProgress" && projectStatus === "beforeContactPartner":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_수출자컨택전_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_수출자컨택전" />
        ),
      };
    case status === "inProgress" && projectStatus === "contactingPartner":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_수출자컨택중" />,
      };
    case status === "inProgress" && projectStatus === "scheduling":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_선적스케쥴안내_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_선적스케쥴안내" />
        ),
      };
    case status === "inProgress" && projectStatus === "containerCarryOut":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_현지공컨테이너반출_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_현지공컨테이너반출" />
        ),
      };
    case status === "inProgress" && projectStatus === "containerPickup":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_현지화물픽업완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_현지화물픽업완료" />
        ),
      };
    case status === "inProgress" && projectStatus === "gateIn":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_현지터미널반입완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_현지터미널반입완료" />
        ),
      };
    case status === "inProgress" && projectStatus === "loaded":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_선적완료" />,
      };
    case status === "inProgress" && projectStatus === "moving":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_출항완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_출항완료" />
        ),
      };
    case status === "inProgress" &&
      projectStatus === "portEntryAndPrepareCustoms":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_입항완료_통관준비" />,
      };
    case status === "inProgress" && projectStatus === "payment":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_정산요청" />,
      };
    case status === "inProgress" && projectStatus === "completeCustoms":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_통관완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_통관완료" />
        ),
      };
    case status === "inProgress" && projectStatus === "delivering":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_내륙운송중_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_내륙운송중" />
        ),
      };
    case status === "finished" && projectStatus === "finished":
    case status === "finished" && projectStatus === "inSettlement":
    case status === "finished" && projectStatus === "scheduling":
      return {
        status: "complete",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_운송완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_운송완료" />
        ),
      };
    case status === "finished" && projectStatus === "failed":
      return {
        status: "reject",
        label: <Trans i18nKey="bid:운송관리_공통_상태_거절" />,
      };
    case status === "finished" && projectStatus === "canceled":
      return {
        status: "cancel",
        label: <Trans i18nKey="bid:운송관리_공통_상태_취소" />,
      };
    default:
      return { status: "before", label: null };
  }
}

function getBidSearchTypeValueString({
  searchType,
  t,
}: {
  searchType?: GET_USER_BID_LIST_REQ_SEARCH_TYPE_V2;
  t: TFunction<"bid">;
}) {
  switch (true) {
    case searchType === "bidId":
      return t("bid:운송관리_필터_검색_옵션_의뢰번호");
    case searchType === "startPoint":
      return t("bid:운송관리_필터_검색_옵션_출발지");
    case searchType === "endPoint":
      return t("bid:운송관리_필터_검색_옵션_도착지");
    case searchType === "itemName":
      return t("bid:운송관리_필터_검색_옵션_상품명");
    case searchType === "blNo":
      return "BL(AWB)";
    case searchType === "poNo":
      return t("bid:운송관리_필터_검색_옵션_PO번호");
    default:
      return "";
  }
}

function getBidStatusFilterValueString(
  filterValue: AllBidStatus,
  t: TFunction<"bid">
) {
  switch (true) {
    case filterValue === "committed":
      return t("운송관리_공통_상태_의뢰확인중");
    case filterValue === "estimating":
      return t("운송관리_공통_상태_견적산출중");
    case filterValue === "waiting":
      return t("운송관리_공통_상태_진행결정요청");
    case filterValue === "waitingForExporterInfo":
      return t("운송관리_공통_상태_수출자입력대기중");
    case filterValue === "beforeContactPartner":
      return t("운송관리_공통_상태_수출자컨택전");
    case filterValue === "contactingPartner":
      return t("운송관리_공통_상태_수출자컨택중");
    // 수출 전용 세부 상태
    case filterValue === "waitingFreight":
      return "화물 준비 중";
    case filterValue === "scheduling":
      return t("운송관리_공통_상태_선적스케쥴안내");
    case filterValue === "containerCarryOut":
      return t("운송관리_공통_상태_현지공컨테이너반출");
    case filterValue === "containerPickup":
      return t("운송관리_공통_상태_현지화물픽업완료");
    // 수출 전용 세부 상태
    case filterValue === "domesticImportCFS":
      return "국내 CFS 픽업 완료";
    case filterValue === "gateIn":
      return t("운송관리_공통_상태_현지터미널반입완료");
    case filterValue === "loaded":
      return t("운송관리_공통_상태_선적완료");
    case filterValue === "moving":
      return t("운송관리_공통_상태_출항완료");
    case filterValue === "portEntryAndPrepareCustoms":
      return t("운송관리_공통_상태_입항완료_통관준비");
    case filterValue === "payment":
      return t("운송관리_공통_상태_정산요청");
    case filterValue === "completeCustoms":
      return t("운송관리_공통_상태_통관완료");
    case filterValue === "delivering":
      return t("운송관리_공통_상태_내륙운송중");
    case filterValue === "finished":
      return t("운송관리_공통_상태_운송완료");
    case filterValue === "canceled":
      return t("운송관리_공통_상태_취소");
    case filterValue === "failed":
      return t("운송관리_공통_상태_거절");
    default:
      return "";
  }
}

function getBidProjectStatusMessage(
  serviceType: ServiceType,
  projectStatus?: BidProjectStatus | BidEventMessageType,
  fbaWareHouse?: FBA.WareHousesFBA
) {
  if (serviceType === ServiceType.FBA) {
    switch (projectStatus) {
      case "scheduling":
        return "선적에 필요한 B/L 발급을 위해 C/I(Commercial Invoice) 및 P/L(Packing List) 전달을 요청드립니다. 메일에 첨부된 양식을 다운로드 하시어 회신 부탁드립니다.";
      case "moving":
        return "정상적으로 화물이 출항했습니다.";
      case "delivering":
        return `미국3PL창고에서 '${formatWareHouse(
          fbaWareHouse
        )}'창고로 이동 중입니다.`;
      case "finished":
        return `화물이 정상적으로 '${formatWareHouse(
          fbaWareHouse
        )}'창고로 입고되었습니다. 아마존 웹사이트에서 입고정보를 확인하실 수 있습니다.`;
      case "canceled":
        return "";
      case "failed":
      default:
        return "";
    }
  } else {
    switch (projectStatus) {
      case "contactingPartner":
        return (
          <Trans i18nKey="mypage:bid.projectStatus.contactingPartnerMsg" />
        );
      case "scheduling":
        return <Trans i18nKey="mypage:bid.projectStatus.schedulingMsg" />;
      case "moving":
        return <Trans i18nKey="mypage:bid.projectStatus.movingMsg" />;
      case "portEntryAndPrepareCustoms":
        return (
          <Trans i18nKey="mypage:bid.projectStatus.portEntryAndPrepareCustomsMsg" />
        );
      case "payment":
        return <Trans i18nKey="mypage:bid.projectStatus.paymentMsg" />;
      case "completeCustoms":
        return <Trans i18nKey="mypage:bid.projectStatus.completeCustomsMsg" />;
      case "delivering":
        return <Trans i18nKey="mypage:bid.projectStatus.deliveringMsg" />;
      case "finished":
        return <Trans i18nKey="mypage:bid.projectStatus.finishedMsg" />;
      case "canceled":
        return "";
      case "failed":
        return "";
      default:
        return "";
    }
  }
}

function getBidStartPoint(params: {
  serviceType?: BidServiceType;
  startCountryCode?: string;
  startCountryName?: string;
  startAddress?: string;
  startPort?: Pick<Port, "id" | "name" | "nameEN">;
  endViaPort?: Pick<Port, "id" | "name" | "nameEN">;
  wareHouse?: Pick<WareHouse, "id" | "name" | "nameEN">; // 창고에서 출발했을때만 전달됨 (내륙출발은 제외됨)
}): React.ReactElement {
  let result: React.ReactElement = <></>;

  if (!params.startCountryCode) {
    return result;
  }

  if (params.serviceType === "consolidation") {
    // consolidation일 경우만 출발지에 창고정보를 표시
    if (params.wareHouse) {
      result = (
        <>
          {params.startCountryName}{" "}
          {i18n.language === "ko-KR"
            ? params.wareHouse.name
            : params.wareHouse.nameEN}{" "}
          <Trans i18nKey="common:warehouse" i18n={i18n} />
        </>
      );
    } else {
      result = (
        <>
          {params.startCountryName}{" "}
          <Trans i18nKey="common:inland" i18n={i18n} />
        </>
      );
    }
  } else {
    if (params.startAddress) {
      result = (
        <>
          {params.startCountryName}{" "}
          <Trans i18nKey="common:inland" i18n={i18n} />
        </>
      );
    } else {
      const targetStartPort = isLocalCountry(params.startCountryCode)
        ? params.endViaPort
        : params.startPort;

      result = (
        <>
          {params.startCountryName}{" "}
          {i18n.language === "ko-KR"
            ? targetStartPort?.name || targetStartPort?.nameEN
            : targetStartPort?.nameEN}
        </>
      );
    }
  }

  return result;
}

function getBidEndPoint(params: {
  endCountryCode?: string;
  endCountryName?: string;
  endAddress?: string;
  endPort?: Pick<Port, "id" | "name" | "nameEN">;
  isForSEO?: boolean;
}): React.ReactElement {
  let result: React.ReactElement = <></>;

  if (!params.endCountryName) {
    return result;
  }

  if (params.endAddress) {
    result = (
      <>
        {params.endCountryName}{" "}
        {i18n.language === "en-SG" && !params.isForSEO && <br />}
        <Trans i18nKey="common:inland" i18n={i18n} />
      </>
    );
  } else {
    result = (
      <>
        {params.endCountryName}{" "}
        {i18n.language === "en-SG" && !params.isForSEO && <br />}
        {i18n.language === "ko-KR"
          ? params.endPort?.name || params.endPort?.nameEN
          : params.endPort?.nameEN}
      </>
    );
  }

  return result;
}

function getFreightTypeString({
  freightType,
  t,
}: {
  freightType: string;
  t: TFunction<"dashboard">;
}) {
  if (!t) return "";

  if (freightType === "AIR") {
    return t("dashboard:수입운송리스트_공통_항공");
  }

  if (freightType === "LCL") {
    return t("dashboard:수입운송리스트_공통_해상LCL");
  }

  if (freightType === "FCL") {
    return t("dashboard:수입운송리스트_공통_해상FCL");
  }

  return t("dashboard:수입운송리스트_공통_해상", { freightType });
}

const getBidSupplyString = ({
  freightType,
  supply,
  bidItems,
}: {
  freightType: FreightType;
  supply: number;
  bidItems: DashboardBidItem[];
}) => {
  if (freightType === "FCL") {
    const totalSupply = bidItems
      .map(({ containerType, quantity }) => `${containerType} x ${quantity}`)
      .join(", ");

    return totalSupply;
  }

  if (freightType === "LCL") return `${supply} CBM`;

  return `${supply} C.W`;
};

const getBidSupplyStringForDashboard = ({
  freightType,
  supply,
  bidItems,
}: {
  freightType: FreightType;
  supply: number;
  bidItems: DashboardBidItem[];
}) => {
  if (freightType === "FCL") {
    const totalSupply = bidItems
      .map(({ containerType, quantity }) => `${containerType} x ${quantity}`)
      .join(", ");

    return totalSupply;
  }

  if (freightType === "LCL") return `${supply} R.TON`;

  return `${supply} C.W`;
};

function getShipScheduleStatusString(status: ShipScheduleStatus) {
  switch (status) {
    case "WAITING":
      return "접수 대기";
    case "OPEN":
      return "접수 중";
    case "CLOSED":
      return "접수 마감";
    default:
      return "";
  }
}

function getInlandTransportString(type?: InlnadTransportType) {
  switch (type) {
    case "sole": {
      return <Trans i18nKey="oceanticket-order:common.solePrice" />;
    }
    case "consol": {
      return <Trans i18nKey="oceanticket-order:common.consolPrice" />;
    }
    case "parcel": {
      return <Trans i18nKey="oceanticket-order:common.boxCharge" />;
    }
    default: {
      return "";
    }
  }
}

/**
 * mypage의 bid상세 페이지 url을 에서 서비스타입에 따라 다르게 할때 사용할 path를 반환.
 * @param serviceType
 */
function getMyPageBidDetailPath(serviceType?: BidServiceType): string {
  switch (serviceType) {
    case "general":
      return "realtime";
    case "oceanTicket":
      return "oceanticket";
    case "consolidation":
      return "consolidation";
    default:
      return "";
  }
}

/**
 * 수출자 정보를 보여줄지 체크
 */
function checkVisibleExporterInfo(status: BidStatus): boolean {
  // 수출자정보는 수출자 정보 입력단계 이후부터 볼 수 있다.
  if (
    status === "committed" ||
    status === "estimating" ||
    status === "waiting"
  ) {
    return false;
  }

  return true;
}

/**
 * 운송정보를 보여줄지 체크
 */
function checkVisibleTransportInfo(
  status: BidStatus,
  projectStatus?: BidProjectStatus
): boolean {
  if (!projectStatus) return false;

  if (status === "inProgress") {
    if (projectStatus !== "beforeContactPartner") {
      return true;
    }
  }

  if (status === "finished") {
    const invalidBidProjectStatusList = ["canceled", "failed", "expired"];

    if (invalidBidProjectStatusList.includes(projectStatus)) {
      return false;
    }

    return true;
  }

  return false;
}

function canReorder({
  serviceType,
  bidLocale,
}: {
  serviceType: BidServiceType;
  bidLocale: SellernoteAppRegion;
}) {
  /**
   * 접속 중인 서비스의 locale과 의뢰시의 locale이 다르면 재주문 불가
   * (도착지 data 구조가 다르기 때문)
   */
  if (bidLocale !== APP_REGION) {
    return false;
  }

  if (serviceType === "FBA") {
    return false;
  }

  // 현재는 한국에서만 '창고입고 후 수입운송' 지원
  if (serviceType === "consolidation" && APP_REGION !== "KR") {
    return false;
  }

  return true;
}

/**
 * 업무의뢰 리스트 필터의 출발지 국가 리스트
 */
const getCountryOptionList = (countries: Country[]) =>
  countries.map((v) => ({
    label: APP_CURRENT_LANGUAGE === "ko" ? v.name : v.nameEN,
    value: v.name,
  }));

/**
 * 도착지가 내륙일때 zoneId를 반환한다.
 */
function getZoneId(props: {
  destinationType?: RealtimePortType;
  destinationInlandZone?: InLand;
  destinationInlandMap?: FormDestinationInlandMap;
}) {
  if (props.destinationType === "inland") {
    if (props.destinationInlandZone) {
      return props.destinationInlandZone.id;
    }

    if (props.destinationInlandMap) {
      return props.destinationInlandMap.zoneId;
    }
  }

  return;
}

function getDestinationAddress({
  destinationType,
  destinationInlandZone,
  destinationInlandEndAddress,
  destinationInlandMap,
}: {
  destinationType?: RealtimePortType;
  destinationInlandZone?: InLand;
  destinationInlandEndAddress?: string;
  destinationInlandMap?: FormDestinationInlandMap;
}) {
  if (destinationType === "inland") {
    if (destinationInlandZone) {
      return destinationInlandEndAddress;
    }

    if (destinationInlandMap) {
      return destinationInlandMap.address;
    }
  }

  return;
}

function getBidTransportSchedule(bidSchedule: {
  ATA?: string;
  ATD?: string;
  ETA?: string;
  ETD?: string;
  startPortCode?: string;
  endPortCode?: string;
}) {
  const initialTransportSchedule: TransportSchedule = {
    startDate: "",
    endDate: "",
    startPortCode: "",
    endPortCode: "",
  };

  if (!bidSchedule)
    return { ...initialTransportSchedule, isValidSchedule: false };

  const {
    ATA,
    ATD,
    ETA,
    ETD,
    startPortCode = "",
    endPortCode = "",
  } = bidSchedule;

  const startDate = ATD || ETD;
  const endDate = ATA || ETA;

  initialTransportSchedule.startPortCode = startPortCode;
  initialTransportSchedule.endPortCode = endPortCode;

  if (Boolean(ETD) || Boolean(ATD)) {
    const startDateType = ATD ? "ATD" : "ETD";

    initialTransportSchedule.startDate = `${startDateType}: ${toFormattedDate(
      startDate,
      APP_CURRENT_LANGUAGE === "ko" ? "YY-MM-DD" : "DD/MM/YY"
    )}`;
  }

  if (Boolean(ETA) || Boolean(ATA)) {
    const endDateType = ATA ? "ATA" : "ETA";

    initialTransportSchedule.endDate = `${endDateType}: ${toFormattedDate(
      endDate,
      APP_CURRENT_LANGUAGE === "ko" ? "YY-MM-DD" : "DD/MM/YY"
    )}`;
  }

  const isValidSchedule = Object.values({
    startDate: initialTransportSchedule.startDate,
    endDate: initialTransportSchedule.endDate,
  }).every(Boolean);

  return {
    ...initialTransportSchedule,
    isValidSchedule,
  };
}
/**
 * 주소지 안에 해당 지역명이 포함하고 있는지 확인하는 함수
 * @param location 지역명 리스트
 * @param address 주소지
 * @returns boolean
 */
const containLocationInAddress = ({
  location,
  address,
}: {
  location: string[];
  address?: string;
}) => {
  return location.some((area) => address?.includes(area));
};

function bidFilterValueSelect<T>({
  filterList,
  filterType,
  key,
}: {
  filterList: SearchValueList;
  filterType: BidFilterType;
  key: keyof BidSearchQuery;
}) {
  return filterList
    .filter((item) => item.type === filterType && item.key === key)
    .map((item) => ("value" in item && item.value) as T);
}

/**
 * 운송관리 > ActiveFilter 상태에 저장된 sort 값을 queryValue로 변경시키는 함수
 *
 * @description
 * 선택 옵션은 하나지만 API Query에 2개의 값을 핸들링하기 위해 사용
 *
 * @example
 * 출발일 빠른 순 > departureAndAsc
 * 출발일 느린 순 > departureAndDesc
 * 도착일 빠른 순 > arrivalAndAsc
 * 도착일 느린 순 > arrivalAndDesc
 *
 * return { sort: "departure" | "arrival", order: "ASC" | "DESC"}
 */
function replaceBidScheduleSortQueryValue(sortValue: string) {
  const bidScheduleSortValue = sortValue as BidScheduleSortOptionType;

  switch (true) {
    case bidScheduleSortValue === "departureAndAsc":
      return { sort: "departure", order: "ASC" };
    case bidScheduleSortValue === "departureAndDesc":
      return { sort: "departure", order: "DESC" };
    case bidScheduleSortValue === "arrivalAndAsc":
      return { sort: "arrival", order: "ASC" };
    case bidScheduleSortValue === "arrivalAndDesc":
      return { sort: "arrival", order: "DESC" };
    default:
      return {};
  }
}

function changedBidExporterInfoFilter({
  savedExporterInfoList,
  exporterInfoList,
}: {
  savedExporterInfoList: ExporterInfo[];
  exporterInfoList: ExporterInfo[];
}) {
  const changedExporterList = exporterInfoList.filter((exporterInfo) => {
    const { exporterGroup: currentExporterGroup } = exporterInfo;

    const savedExporterInfo = savedExporterInfoList.find(
      ({ exporterGroup: savedExporterGroup }) =>
        currentExporterGroup === savedExporterGroup
    );

    if (!savedExporterInfo) return false;

    return !checkEqualObject(exporterInfo, savedExporterInfo);
  });

  return changedExporterList;
}

function checkExporterListValidation(exporterInfoList: ExporterInfo[]) {
  return exporterInfoList.map(
    ({ companyName, personName, personEmail, personPhone }) => {
      const companyValidation = validateValueLength({
        value: companyName,
        max: 100,
      });

      const nameValidation = validateValueLength({
        value: personName,
        max: 100,
      });

      const emailValidation =
        // 빈칸일 때 로직을 추가(수출자 운송상세에서만 검증 필요)
        !personEmail || personEmail.trim() === ""
          ? { result: false }
          : validateEmail(personEmail);

      return {
        companyValidation,
        nameValidation,
        emailValidation,
      };
    }
  );
}

/** 운송관리 엑셀 다운로드에서 사용 */
function getBidAllStatusString({
  status,
  projectStatus,
  t,
}: {
  status?: "all" | BidStatus;
  projectStatus?: BidProjectStatus;
  t: TFunction<"bid">;
}) {
  switch (true) {
    case status === "committed":
      return t("운송관리_공통_상태_의뢰확인중");
    case status === "estimating":
      return t("운송관리_공통_상태_견적산출중");
    case status === "waiting":
      return t("운송관리_공통_상태_진행결정요청");
    case status === "waitingForExporterInfo":
      return t("운송관리_공통_상태_수출자입력대기중");
    case status === "inProgress" && projectStatus === "beforeContactPartner":
      return t("운송관리_공통_상태_수출자컨택전");
    case status === "inProgress" && projectStatus === "contactingPartner":
      return t("운송관리_공통_상태_수출자컨택중");
    case status === "inProgress" && projectStatus === "scheduling":
      return t("운송관리_공통_상태_선적스케쥴안내");
    case status === "inProgress" && projectStatus === "containerCarryOut":
      return t("운송관리_공통_상태_현지공컨테이너반출");
    case status === "inProgress" && projectStatus === "containerPickup":
      return t("운송관리_공통_상태_현지화물픽업완료");
    case status === "inProgress" && projectStatus === "gateIn":
      return t("운송관리_공통_상태_현지터미널반입완료");
    case status === "inProgress" && projectStatus === "loaded":
      return t("운송관리_공통_상태_선적완료");
    case status === "inProgress" && projectStatus === "moving":
      return t("운송관리_공통_상태_출항완료");
    case status === "inProgress" &&
      projectStatus === "portEntryAndPrepareCustoms":
      return t("운송관리_공통_상태_입항완료_통관준비");
    case status === "inProgress" && projectStatus === "payment":
      return t("운송관리_공통_상태_정산요청");
    case status === "inProgress" && projectStatus === "completeCustoms":
      return t("운송관리_공통_상태_통관완료");
    case status === "inProgress" && projectStatus === "delivering":
      return t("운송관리_공통_상태_내륙운송중");
    case status === "finished" && projectStatus === "finished":
    case status === "finished" && projectStatus === "inSettlement":
    case status === "finished" && projectStatus === "scheduling":
      return t("운송관리_공통_상태_운송완료");
    case status === "finished" && projectStatus === "failed":
      return t("운송관리_공통_상태_거절");
    case status === "finished" && projectStatus === "canceled":
      return t("운송관리_공통_상태_취소");
    default:
      return "";
  }
}

function getBidEventMessage(
  bidEventsMessageType: BidEventsMessageType,
  isInvoiceSended: boolean,
  t: TFunction<"bid">
) {
  switch (true) {
    /** 수출자 컨택 전 */
    case bidEventsMessageType === "beforeContactPartner":
      return t("운송관리_상세_운송현황_리스트_수출자컨택전_설명");
    /** 수출자 컨택 중 */
    case bidEventsMessageType === "contactingPartner":
      return t("운송관리_상세_운송현황_리스트_수출자컨택중_설명");
    /** 선적 스케줄 안내 */
    case bidEventsMessageType === "scheduling":
      return t("운송관리_상세_운송현황_리스트_선적스케쥴안내_설명");
    /** 현지 공 컨테이너 반출 */
    case bidEventsMessageType === "containerCarryOut":
      return t("운송관리_상세_운송현황_리스트_공컨테이너반출_설명");
    /** 현지 픽업 완료 */
    case bidEventsMessageType === "containerPickup":
      return t("운송관리_상세_운송현황_리스트_현지픽업완료_설명");
    /** 현지 터미널 반입 완료 */
    case bidEventsMessageType === "gateIn":
      return t("운송관리_상세_운송현황_리스트_현지터미널반입완료_설명");
    /** 선적 완료 */
    case bidEventsMessageType === "loaded":
      return t("운송관리_상세_운송현황_리스트_선적완료_설명");
    /** 출항 완료 */
    case bidEventsMessageType === "moving":
      return t("운송관리_상세_운송현황_리스트_출항완료_설명");
    /** 입항 완료 및 통관 준비 */
    case bidEventsMessageType === "portEntryAndPrepareCustoms":
      return t("운송관리_상세_운송현황_리스트_입항완료_통관준비_설명");
    /** 정산 요청, 거래명세서 발송 전 */
    case bidEventsMessageType === "payment" && !isInvoiceSended:
      return t(
        "운송관리_상세_운송현황_리스트_정산요청_거래명세서_발송_전_설명"
      );
    /** 정산 요청, 거래명세서 발송 후 */
    case bidEventsMessageType === "payment" && isInvoiceSended:
      return t(
        "운송관리_상세_운송현황_리스트_정산요청_거래명세서_발송_후_설명"
      );
    /** 통관 완료 */
    case bidEventsMessageType === "completeCustoms":
      return t("운송관리_상세_운송현황_리스트_통관완료_설명");
    /** 국내 내륙 운송 중 */
    case bidEventsMessageType === "delivering":
      return t("운송관리_상세_운송현황_리스트_국내내륙운송중_설명");
    /** 운송 완료 */
    case bidEventsMessageType === "finished":
      return t("운송관리_상세_운송현황_리스트_운송완료_설명");
    /** 에외사항 */
    case bidEventsMessageType === "special":
      return "";
    default:
      return "";
  }
}

function getBidEventStatusToString(
  bidEventsMessageType: BidEventsMessageType,
  t: TFunction<"bid">
) {
  switch (true) {
    case bidEventsMessageType === "beforeContactPartner":
      return t("운송관리_공통_상태_수출자컨택전");
    case bidEventsMessageType === "contactingPartner":
      return t("운송관리_공통_상태_수출자컨택중");
    case bidEventsMessageType === "scheduling":
      return t("운송관리_공통_상태_선적스케쥴안내");
    case bidEventsMessageType === "containerCarryOut":
      return t("운송관리_공통_상태_현지공컨테이너반출");
    case bidEventsMessageType === "containerPickup":
      return t("운송관리_공통_상태_현지화물픽업완료");
    case bidEventsMessageType === "gateIn":
      return t("운송관리_공통_상태_현지터미널반입완료");
    case bidEventsMessageType === "loaded":
      return t("운송관리_공통_상태_선적완료");
    case bidEventsMessageType === "moving":
      return t("운송관리_공통_상태_출항완료");
    case bidEventsMessageType === "portEntryAndPrepareCustoms":
      return t("운송관리_공통_상태_입항완료_통관준비");
    case bidEventsMessageType === "payment":
      return t("운송관리_공통_상태_정산요청");
    case bidEventsMessageType === "completeCustoms":
      return t("운송관리_공통_상태_통관완료");
    case bidEventsMessageType === "delivering":
      return t("운송관리_공통_상태_내륙운송중");
    case bidEventsMessageType === "finished":
      return t("운송관리_공통_상태_운송완료");
    case bidEventsMessageType === "special":
      return "";
    default:
      return "";
  }
}

function getBidEventStartMessage(
  status: BidStatus | undefined,
  projectStatus: BidProjectStatus | undefined,
  t: TFunction<"bid">
) {
  switch (true) {
    case status === "committed":
    case status === "estimating":
      return t("운송관리_상세_운송현황_리스트_의뢰확인_견적산출_설명");

    case status === "waiting":
      return t("운송관리_상세_운송현황_리스트_진행결정요청_설명");

    case status === "waitingForExporterInfo":
      return t("운송관리_상세_운송현황_리스트_수출자정보입력_설명");

    case status === "finished" &&
      (projectStatus === "failed" ||
        projectStatus === "canceled" ||
        projectStatus === "expired"):
      return t("운송관리_상세_운송현황_리스트_의뢰취소_설명");

    default:
      return t("운송관리_상세_운송현황_리스트_수출자정보입력_설명");
  }
}

function getBidEventStartTitle(
  status: BidStatus | undefined,
  projectStatus: BidProjectStatus | undefined,
  t: TFunction<"bid">
) {
  switch (true) {
    case status === "committed":
      return t("운송관리_공통_상태_의뢰확인중");

    case status === "estimating":
      return t("운송관리_공통_상태_견적산출중");

    case status === "waiting":
    case status === "waitingForExporterInfo":
      return t("운송관리_공통_상태_진행결정요청");

    case status === "finished" && projectStatus === "failed":
      return t("운송관리_공통_상태_거절");

    case status === "finished" && projectStatus === "canceled":
      return t("운송관리_공통_상태_취소");

    default:
      return t("운송관리_공통_상태_수출자입력대기중");
  }
}

function getExportForwardingEventStatusToString(
  bidEventsMessageType: BidEventsMessageType,
  t: TFunction<"bid-export">
) {
  switch (true) {
    case bidEventsMessageType === "waitingFreight":
      return "화물 준비 중";
    case bidEventsMessageType === "scheduling":
      return t("운송관리_공통_상태_선적스케쥴안내");
    case bidEventsMessageType === "containerCarryOut":
      return "공 컨테이너 반출";
    case bidEventsMessageType === "containerPickup":
      return "국내 화물 픽업완료";
    case bidEventsMessageType === "domesticImportCFS":
      return "국내 CFS 픽업완료";
    case bidEventsMessageType === "gateIn":
      return "국내 터미널 반입 완료";
    case bidEventsMessageType === "loaded":
      return "선적완료";
    case bidEventsMessageType === "moving":
      return t("운송관리_공통_상태_출항완료");
    case bidEventsMessageType === "portEntryAndPrepareCustoms":
      return t("운송관리_공통_상태_입항완료_통관준비");
    case bidEventsMessageType === "completeCustoms":
      return t("운송관리_공통_상태_통관완료");
    case bidEventsMessageType === "delivering":
      return "국내 내륙 운송 중";
    case bidEventsMessageType === "finished":
      return t("운송관리_공통_상태_운송완료");
    case bidEventsMessageType === "special":
      return "";
    default:
      return "";
  }
}

function getExportForwardingEventStartMessage(
  status: BidStatus | undefined,
  projectStatus: BidProjectStatus | undefined,
  t: TFunction<"bid">
) {
  switch (true) {
    case status === "committed":
    case status === "estimating":
      return t("운송관리_상세_운송현황_리스트_의뢰확인_견적산출_설명");

    case status === "waiting":
      return t("운송관리_상세_운송현황_리스트_진행결정요청_설명");

    case status === "finished" &&
      (projectStatus === "failed" ||
        projectStatus === "canceled" ||
        projectStatus === "expired"):
      return t("운송관리_상세_운송현황_리스트_의뢰취소_설명");

    default:
      return t("운송관리_상세_운송현황_리스트_진행결정요청_설명");
  }
}

function getExportForwardingEventMessage(
  bidEventsMessageType: BidEventsMessageType,
  t: TFunction<"bid-export">
) {
  switch (true) {
    /** 화물 준비 중 */
    case bidEventsMessageType === "waitingFreight":
      return "견적을 수락하셨습니다. 운송 진행을 위해 필수서류(C/I, P/L, 수출신고필증, 사업자등록증)을 업로드 해주세요. 선적 가능한 스케줄을 곧 안내 드리겠습니다.";
    /** 선적 스케줄 안내 */
    case bidEventsMessageType === "scheduling":
      return "선적 스케줄을 확인 해주세요. 변경을 원하시면 담당 매니저에게 문의 주세요.";
    /** 현지 공 컨테이너 반출 */
    case bidEventsMessageType === "containerCarryOut":
      return "공 컨테이너가 반출되어 화물 픽업을 위해 국내 내륙주소로 운송 중입니다.";
    /** 현지 픽업 완료 */
    case bidEventsMessageType === "containerPickup":
      return "국내 내륙주소에서 화물이 픽업완료되어 선적항 터미널로 이동 중입니다.";
    /** 국내 터미널 반입 완료(수출 LCL에서 적용 현지픽업완료와 동일) */
    case bidEventsMessageType === "domesticImportCFS":
      return "선적 예정인 컨테이너가 국내 터미널에 정상적으로 반입 완료되었습니다.";
    case bidEventsMessageType === "gateIn":
      return "선적 예정인 컨테이너가 국내 터미널에 정상적으로 반입 완료되었습니다.";
    /** 선적 완료 */
    case bidEventsMessageType === "loaded":
      return t("운송관리_상세_운송현황_리스트_선적완료_설명");
    /** 출항 완료 */
    case bidEventsMessageType === "moving":
      return t("운송관리_상세_운송현황_리스트_출항완료_설명");
    /** 입항 완료 및 통관 준비 */
    case bidEventsMessageType === "portEntryAndPrepareCustoms":
      return "화물이 정상적으로 입항했습니다.";
    /** 정산 요청 */

    /** 통관 완료 */
    case bidEventsMessageType === "completeCustoms":
      return t("운송관리_상세_운송현황_리스트_통관완료_설명");
    /** 국내 내륙 운송 중 */
    case bidEventsMessageType === "delivering":
      return "화물이 현지 내륙 운송 중 입니다.";
    /** 운송 완료 */
    case bidEventsMessageType === "finished":
      return "최종목적지에 화물이 도착했습니다. 금번 의뢰 건을 종료합니다.";
    /** 에외사항 */
    case bidEventsMessageType === "special":
      return "";
    default:
      return "";
  }
}

function getExportForwardingEventStartTitle(
  status: BidStatus | undefined,
  projectStatus: BidProjectStatus | undefined,
  t: TFunction<"bid">
) {
  switch (true) {
    case status === "committed":
      return t("운송관리_공통_상태_의뢰확인중");

    case status === "estimating":
      return t("운송관리_공통_상태_견적산출중");

    case status === "waiting":
      return t("운송관리_공통_상태_진행결정요청");

    case status === "finished" && projectStatus === "failed":
      return t("운송관리_공통_상태_거절");

    case status === "finished" && projectStatus === "canceled":
      return t("운송관리_공통_상태_취소");

    default:
      return t("운송관리_공통_상태_진행결정요청");
  }
}

/**
 * projectStatus 에 추가된 status 를 문자열로 변환
 */
function getExportProjectStatusStringV2({
  projectStatus,
  t,
}: {
  projectStatus?: BidProjectStatus;
  t: TFunction<"dashboard">;
}) {
  switch (projectStatus) {
    case "waitingFreight":
      return "화물 준비 중";
    case "scheduling":
      return t("dashboard:수입운송리스트_공통_상태_선적스케줄안내");
    case "containerCarryOut":
      return "공 컨테이너 반출";
    case "containerPickup":
      return "국내 픽업 완료";
    case "domesticImportCFS":
      return "국내 CFS 픽업완료";
    case "gateIn":
      return "국내 터미널 반입 완료";
    case "loaded":
      return t("dashboard:수입운송리스트_공통_상태_선적완료");
    case "moving":
      return t("dashboard:수입운송리스트_공통_상태_출항완료");
    case "portEntryAndPrepareCustoms":
      return t("dashboard:수입운송리스트_공통_상태_입항완료및통관준비");
    case "completeCustoms":
      return t("dashboard:수입운송리스트_공통_상태_통관완료");
    case "delivering":
      return t("dashboard:수입운송리스트_공통_상태_내륙운송중");
    case "settlementRejected":
    case "settlementConfirmed":
    case "settlementSecondConfirmed":
    case "settlementComplete":
    case "finished":
      return t("dashboard:수입운송리스트_공통_상태_운송완료");
    case "canceled":
      return t("dashboard:수입운송리스트_공통_상태_취소");
    case "failed":
      return t("dashboard:수입운송리스트_공통_상태_거절");
    default:
      return "";
  }
}

function getExportStateBadgeProps({
  status,
  projectStatus,
  isNewLine,
}: {
  status?: "all" | BidStatus;
  projectStatus?: BidProjectStatus;
  isNewLine?: boolean;
}): Omit<StateBadgeForForwardingProps, "type" | "size"> {
  switch (true) {
    case status === "committed":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_의뢰확인중" />,
      };
    case status === "estimating":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_견적산출중" />,
      };
    case status === "waiting":
    case status === "waitingForExporterInfo":
      return {
        status: "before",
        label: <Trans i18nKey="bid:운송관리_공통_상태_진행결정요청" />,
      };
    // 수출 전용 세부 상태
    case status === "inProgress" && projectStatus === "waitingFreight":
      return {
        status: "ongoing",
        label: "화물 준비 중",
      };

    case status === "inProgress" && projectStatus === "scheduling":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_선적스케쥴안내_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_선적스케쥴안내" />
        ),
      };
    case status === "inProgress" && projectStatus === "containerCarryOut":
      return {
        status: "ongoing",
        label: "공 컨테이너 반출",
      };
    case status === "inProgress" && projectStatus === "containerPickup":
      return {
        status: "ongoing",
        label: "국내 화물 픽업완료",
      };
    case status === "inProgress" && projectStatus === "domesticImportCFS":
      return {
        status: "ongoing",
        label: "국내 CFS 픽업완료",
      };
    case status === "inProgress" && projectStatus === "gateIn":
      return {
        status: "ongoing",
        label: "국내 터미널 반입 완료",
      };
    case status === "inProgress" && projectStatus === "loaded":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_선적완료" />,
      };
    case status === "inProgress" && projectStatus === "moving":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_출항완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_출항완료" />
        ),
      };
    case status === "inProgress" &&
      projectStatus === "portEntryAndPrepareCustoms":
      return {
        status: "ongoing",
        label: <Trans i18nKey="bid:운송관리_공통_상태_입항완료_통관준비" />,
      };
    case status === "inProgress" && projectStatus === "completeCustoms":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_통관완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_통관완료" />
        ),
      };
    case status === "inProgress" && projectStatus === "delivering":
      return {
        status: "ongoing",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_내륙운송중_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_내륙운송중" />
        ),
      };
    case status === "finished" && projectStatus === "finished":
    case status === "finished" && projectStatus === "inSettlement":
    case status === "finished" && projectStatus === "scheduling":
      return {
        status: "complete",
        label: isNewLine ? (
          <Trans i18nKey="bid:운송관리_공통_상태_운송완료_개행" />
        ) : (
          <Trans i18nKey="bid:운송관리_공통_상태_운송완료" />
        ),
      };
    case status === "finished" && projectStatus === "failed":
      return {
        status: "reject",
        label: <Trans i18nKey="bid:운송관리_공통_상태_거절" />,
      };
    case status === "finished" && projectStatus === "canceled":
      return {
        status: "cancel",
        label: <Trans i18nKey="bid:운송관리_공통_상태_취소" />,
      };
    default:
      return { status: "before", label: null };
  }
}

export type { BidScheduleSortOptionType };

export {
  checkVisibleExporterInfo,
  checkVisibleTransportInfo,
  getInlandTransportString,
  getShipScheduleStatusString,
  getBidEndPoint,
  getBidStartPoint,
  getBidProjectStatusMessage,
  getBidProjectStatusString,
  getBidProjectStatusStringV2,
  getBidAllStatusString,
  getBidStatusFilterValueString,
  getBidSearchTypeValueString,
  getMyPageBidDetailPath,
  getBidStatusString,
  getBidStateBadgeProps,
  getBidSupplyString,
  getBidSupplyStringForDashboard,
  getBidTransportSchedule,
  getBidEventMessage,
  getBidEventStatusToString,
  getBidEventStartTitle,
  getBidEventStartMessage,
  checkCanStack,
  checkIsDangerous,
  getFreightTypeString,
  getCountryOptionList,
  getZoneId,
  getDestinationAddress,
  canReorder,
  containLocationInAddress,
  bidFilterValueSelect,
  replaceBidScheduleSortQueryValue,
  changedBidExporterInfoFilter,
  checkExporterListValidation,
  getExportForwardingEventStatusToString,
  getExportForwardingEventStartMessage,
  getExportForwardingEventMessage,
  getExportForwardingEventStartTitle,
  getExportProjectStatusStringV2,
  getExportStateBadgeProps,
};
