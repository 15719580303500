import produce from "immer";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import {
  AssistanceForm,
  AssistanceStep,
} from "@sellernote/_shared/src/types/forwarding/assistance";
import {
  QuotationDownloadLink,
  RealtimeQuotationLCLGeneral,
} from "@sellernote/_shared/src/types/forwarding/quotation";
import { getExpiredAt } from "@sellernote/_shared/src/utils/common/date";
import { getUpdatedObject } from "@sellernote/_shared/src/utils/common/etc";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { actionType } from "./actions";

export interface AssistanceState {
  order: {
    stepIndex: number;
    chatIndex: {
      [P in AssistanceStep]?: number;
    };
    form: Partial<AssistanceForm>;
    realtimeQuotation: Partial<ApiResponseState<RealtimeQuotationLCLGeneral>>;
    sendRequest: Partial<ApiResponseState<any>>;
    quotationDownloadLink: Partial<QuotationDownloadLink>;
  };
}

const testState = {
  order: {
    stepIndex: 4,
    chatIndex: {
      step1: 8,
      step2: 6,
      step3: 7,
      step4Failure: 0,
      step4Success: 1,
    },
    form: {
      destinationInlandType: "zone",
      name: "test",
      fobPrice: "233",
      departurePort: {
        id: 43,
        code: "CNSGH",
        codeMT: null,
        name: "상해항",
        nameC: "상하이항",
        nameEN: "Shanghai port",
        country: "CN",
        region: "Asia",
        type: "sea",
        order: 1,
        isDefault: true,
        lat: 31.36636,
        lng: 121.6147,
        isOceanTicketOriginPort: true,
        isActiveOceanTicketPort: true,
        countryName: "중국",
      },
      destinationPort: {
        id: 9,
        code: "KRINC",
        codeMT: "KRINC",
        name: "인천항",
        nameC: "인천구항",
        nameEN: "Incheon port",
        country: "KR",
        region: "Asia",
        type: "sea",
        order: 1,
        isDefault: true,
        lat: 37.45,
        lng: 126.61666666666666,
        isOceanTicketOriginPort: true,
        isActiveOceanTicketPort: true,
        countryName: "한국",
      },
      destinationInlandZone: {
        id: 19497,
        name: "부산광역시 남구 용호1동",
        bCode: "2629010700",
        hAddress: "부산 남구 용호1동",
      },
      destinationInlandEndAddress: "부산 남구 분포로 41 (용호동, 분포초등학교)",
      destinationInlandAddressDetail: "3",
      cbm: 23,
      weight: 3,
    },
    realtimeQuotation: {
      data: {
        freightType: "LCL",
        isOceanTicket: false,
        fareId: 142,
        userId: 399,
        cost: 1702903,
        containInland: true,
        exchangeRate: 1323.2,
        isExpectVAT: true,
        domesticFee: [
          {
            key: "thc",
            item: "THC(터미널 화물 처리비)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "KRW",
            itemPrice: 172500,
            unitPrice: 7500,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "wfg",
            item: "WFG(부두사용료)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "KRW",
            itemPrice: 7429,
            unitPrice: 323,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "ccc",
            item: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "KRW",
            itemPrice: 57500,
            unitPrice: 2500,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "hc",
            item: "HANDLING CHARGE(포워더 대행 수수료)",
            note: "",
            amount: 1,
            atCost: false,
            currency: "KRW",
            itemPrice: 40000,
            unitPrice: 40000,
            isVAT: true,
            itemUnitMeasurement: "B/L",
          },
          {
            key: "docFee",
            item: "DOC FEE(서류 발급비)",
            note: "",
            amount: 1,
            atCost: false,
            currency: "KRW",
            itemPrice: 50000,
            unitPrice: 50000,
            isVAT: true,
            itemUnitMeasurement: "B/L",
          },
          {
            key: "doFee",
            item: "D/O FEE(화물인도지시서)",
            note: "",
            amount: 1,
            atCost: false,
            currency: "KRW",
            itemPrice: 50000,
            unitPrice: 50000,
            isVAT: true,
            itemUnitMeasurement: "B/L",
          },
          {
            key: "drayageCharge",
            item: "DRAYAGE CHARGE(셔틀비)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "KRW",
            itemPrice: 195500,
            unitPrice: 8500,
            isVAT: true,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "cfsCharge",
            item: "CFS CHARGE (화물 혼재/분류 비용)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "KRW",
            itemPrice: 0,
            unitPrice: 0,
            isVAT: true,
            itemUnitMeasurement: "R.TON",
          },
        ],
        freightFee: [
          {
            key: "oceanFreight",
            item: "OCEAN FREIGHT(해상운임)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "USD",
            itemPrice: 0,
            unitPrice: 0,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "bafCaf",
            item: "BAF & CAF(유류할증료&통화할증료)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "USD",
            itemPrice: 578239,
            unitPrice: 19,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
          {
            key: "totalAdditionalFee",
            item: "CRS(긴급비용할증료)",
            note: "",
            amount: 23,
            atCost: false,
            currency: "USD",
            itemPrice: 121735,
            unitPrice: 4,
            isVAT: false,
            itemUnitMeasurement: "R.TON",
          },
        ],
        localFee: [],
        inlandFee: [
          {
            key: "tc",
            item: "TRUCKING CHARGE(내륙운송료)",
            note: "독차",
            isVAT: true,
            amount: 1,
            atCost: false,
            currency: "KRW",
            itemPrice: 430000,
            unitPrice: 430000,
            itemUnitMeasurement: "TRUCK",
          },
        ],
        taxFee: [],
        otherFee: [],
        fileKey: null,
        id: 2824,
        createdAt: "2023-03-06T01:48:59.000Z",
        searchEstimateId: 2824,
        fare: {
          id: 142,
          expiredAt: "2022-02-28",
          transitType: "direct",
          liner: "장금상선",
          linerId: 0,
          freightPaymentType: "cc",
          forwarderId: 23,
        },
        quotationInfoForDownload: {
          serviceType: "general",
          freightType: "LCL",
          currency: {
            usd: "1323.2",
            euro: "1402.77",
            yuan: "192.03",
          },
          request: {
            fob: "FOB조건(상해항 -> 인천항 -> 부산 남구 분포로 41 (용호동, 분포초등학교))",
            item: "test",
            load: "23R.TON",
          },
          oceanFreight: [
            {
              category: "OCEAN FREIGHT(해상운임)",
              shipda: "0",
              quantity: "23",
              cost: "0",
              unit: "R.TON",
              shipdaC: "USD",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
            {
              category: "BAF & CAF(유류할증료&통화할증료)",
              shipda: "19",
              quantity: "23",
              cost: "578,239",
              unit: "R.TON",
              shipdaC: "USD",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
            {
              category: "CRS(긴급비용할증료)",
              shipda: "4",
              quantity: "23",
              cost: "121,735",
              unit: "R.TON",
              shipdaC: "USD",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
          ],
          domesticCost: [
            {
              category: "THC(터미널 화물 처리비)",
              shipda: "7,500",
              quantity: "23",
              cost: "172,500",
              unit: "R.TON",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
            {
              category: "WFG(부두사용료)",
              shipda: "323",
              quantity: "23",
              cost: "7,429",
              unit: "R.TON",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
            {
              category: "CONTAINER CLEANING CHARGE(컨테이너 청소 비용)",
              shipda: "2,500",
              quantity: "23",
              cost: "57,500",
              unit: "R.TON",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: false,
              note: "",
            },
            {
              category: "HANDLING CHARGE(포워더 대행 수수료)",
              shipda: "40,000",
              quantity: "1",
              cost: "40,000",
              unit: "B/L",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "",
            },
            {
              category: "DOC FEE(서류 발급비)",
              shipda: "50,000",
              quantity: "1",
              cost: "50,000",
              unit: "B/L",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "",
            },
            {
              category: "D/O FEE(화물인도지시서)",
              shipda: "50,000",
              quantity: "1",
              cost: "50,000",
              unit: "B/L",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "",
            },
            {
              category: "DRAYAGE CHARGE(셔틀비)",
              shipda: "8,500",
              quantity: "23",
              cost: "195,500",
              unit: "R.TON",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "",
            },
            {
              category: "CFS CHARGE (화물 혼재/분류 비용)",
              shipda: "0",
              quantity: "23",
              cost: "0",
              unit: "R.TON",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "",
            },
          ],
          inlandCost: [
            {
              category: "TRUCKING CHARGE(내륙운송료)",
              shipda: "430,000",
              quantity: "1",
              cost: "430,000",
              unit: "TRUCK",
              shipdaC: "KRW",
              costC: "KRW",
              isVAT: true,
              note: "독차",
            },
          ],
          localCost: [],
          taxCost: [],
          otherCost: [],
          etc: [
            {
              category: "ROUTE(구간)",
              detail:
                "상해항 -> 인천항 -> 부산 남구 분포로 41 (용호동, 분포초등학교)",
            },
            {
              category: "TRANSIT TIME(소요시간)",
              detail: "3일",
            },
            {
              category: "VALIDITY(운임 유효기간)",
              detail: "2022년 02월 28일",
            },
          ],
          comment:
            "* 견적서 내 회색 행은 VAT 별도입니다. <br> - 국내 창고보관료는 실비로 청구됩니다. <br> \n      - 실제 적용되는 환율은 외환은행이 고지한 전신환매도율로 책정됩니다. <br> \n      - 실시간 견적조회 서비스 내 '체크 포인트'에서 선택하지 않았으나 실제 발생하게 될 경우 실비로 청구됩니다. <br> \n      - 최종정산은 실제 선적된 화물의 부피 및 중량 정보를 바탕으로 청구됩니다.",
          total: "1,702,903",
          locale: "KR",
          adminContact: {
            email: "hjbang@ship-da.com",
            phone: "02-6956-7213",
            name: "방현정",
          },
        },
        originalPrice: 1702903,
        firstDiscountPrice: 0,
        estimateLogId: 6087,
      },
      status: "SUCCESS",
    },
    sendRequest: {},
    quotationDownloadLink: {},
  },
};

// Reducer
const initialState: AssistanceState = {
  order: {
    stepIndex: 1,
    chatIndex: {
      step1: 0,
      step2: 0,
      step3: 0,
      step4Failure: 0,
      step4Success: 0,
    },
    form: {
      destinationInlandType: APP_REGION === "KR" ? "zone" : "map",
    },
    realtimeQuotation: apiResponseInitialState,
    sendRequest: apiResponseInitialState,
    quotationDownloadLink: {
      ...apiResponseInitialState,
      needInitialDownload: undefined,
      expiredAt: undefined,
    },
  },
};

export const reducer = (state = initialState, action: any) => {
  return produce(state, (draft: AssistanceState) => {
    switch (action.type) {
      case actionType.INIT_ORDER: {
        draft.order = initialState.order;
        break;
      }

      case actionType.ORDER_UPDATE_STEP_INDEX: {
        draft.order.stepIndex = action.payload;
        break;
      }

      case actionType.ORDER_UPDATE_CHAT_INDEX: {
        draft.order.chatIndex![action.payload.step as AssistanceStep] =
          action.payload.value;
        break;
      }

      case actionType.ORDER_UPDATE_FORM_ITEM: {
        draft.order.form = getUpdatedObject(
          draft.order.form!,
          action.payload.key,
          action.payload.value
        );
        break;
      }

      case actionType.ORDER_GET_REALTIME_QUOTATION_SUCCESS: {
        draft.order.realtimeQuotation = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }

      case actionType.ORDER_GET_REALTIME_QUOTATION_FAILURE: {
        draft.order.realtimeQuotation = {
          data: undefined,
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_REALTIME_QUOTATION: {
        draft.order.realtimeQuotation = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_SEND_REQUEST_SUCCESS: {
        draft.order.sendRequest = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }

      case actionType.ORDER_SEND_REQUEST_FAILURE: {
        draft.order.sendRequest = {
          data: null,
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_SEND_REQUEST: {
        draft.order.sendRequest = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS: {
        const duration =
          action.payload.preSignedUrl &&
          action.payload.preSignedUrl.expirationTime;

        draft.order.quotationDownloadLink = {
          data: action.payload,
          status: "SUCCESS",
          needInitialDownload: "yes",
          expiredAt: getExpiredAt(duration - 60), // buffer로 1분을 추가
        };
        break;
      }

      case actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE: {
        draft.order.quotationDownloadLink = {
          data: null,
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK: {
        draft.order.quotationDownloadLink =
          initialState.order.quotationDownloadLink;
        break;
      }

      case actionType.UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD: {
        draft.order.quotationDownloadLink!.needInitialDownload = action.payload;
        break;
      }

      default:
        break;
    }
  });
};
