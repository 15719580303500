import { enableES5 } from "immer";
enableES5();
import { HYDRATE } from "next-redux-wrapper";
import { combineReducers, Reducer } from "redux";
import { all } from "redux-saga/effects";

import {
  reducer as featuresReducer,
  saga as featuresSaga,
} from "@sellernote/_shared/src/reduxFeatures/combinedSlice";
import { LoadingState } from "@sellernote/_shared/src/types/common/loading";

import { sampleReducer, sampleSaga } from "./_sample";
import { SampleState } from "./_sample/slice";
import { assistanceReducer, assistanceSaga } from "./assistance";
import { AssistanceState } from "./assistance/reducer";
import { authReducer, authSaga } from "./auth";
import { AuthState } from "./auth/reducer";
import { commonReducer, commonSaga } from "./common";
import { CommonState } from "./common/reducer";
import { consolidationReducer, consolidationSaga } from "./consolidation";
import { ConsolidationState } from "./consolidation/slice";
import { downloadReducer, downloadSaga } from "./download";
import { DownloadState } from "./download/reducer";
import { fileReducer, fileSaga } from "./file";
import { FileState } from "./file/slice";
import { fulfillmentReducer, fulfillmentSaga } from "./fulfillment";
import { FulfillmentState } from "./fulfillment/slice";
import { loadingReducer } from "./loading";
import { oceanTicketReducer, oceanTicketSaga } from "./oceanTicket";
import { OceanTicketState } from "./oceanTicket/reducer";
import { supportReducer, supportSaga } from "./support";
import { SupportState } from "./support/slice";
import { uploadReducer, uploadSaga } from "./upload";
import { UploadState } from "./upload/reducer";

export interface RootState {
  assistance: AssistanceState;
  consolidation: ConsolidationState;
  oceanTicket: OceanTicketState;
  loading: LoadingState;
  auth: AuthState;
  common: CommonState;
  support: SupportState;
  download: DownloadState;
  upload: UploadState;
  sample: SampleState;
  toolkitSample: SampleState;
  fulfillment: FulfillmentState;
  file: FileState;
  features: ReturnType<typeof featuresReducer>;
}

const combinedReducer = combineReducers({
  auth: authReducer,
  assistance: assistanceReducer,
  consolidation: consolidationReducer,
  oceanTicket: oceanTicketReducer,
  common: commonReducer,
  support: supportReducer,
  loading: loadingReducer,
  download: downloadReducer,
  upload: uploadReducer,
  sample: sampleReducer,
  toolkitSample: sampleReducer,
  fulfillment: fulfillmentReducer,
  file: fileReducer,
  features: featuresReducer,
});

export const rootReducer: Reducer = (state: RootState, action: any) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use client state
      ...getStateToOverwrite({
        originalState: state,
        stateFromHydration: action.payload,
      }), // apply delta from hydration
    };

    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};
/**
 * HYDRATE를 할 때 적절히 reconciliation해주지 않으면 client state를 덮어쓰게되어 오류가 생긴다.
 * 커뮤니티에서는 server state와 client state를 나누는 것이 가장 깔끔하다고 하지만 지금 구조상 변경하는 것은 무리로 판단되며,
 * 그것이 아니라면 client state가 우선시 되야되는 경우를 코드에 명시하는 식의 가이드밖에 없으나,
 * 특정 페이지만을 HYDRATE가 필요한 우리 상황에는 그 반대의 방식인 HYDRATE가 필요한 state에 대해서만 server state를 우선하는 방식으로 처리한다.
 */
function getStateToOverwrite({
  originalState,
  stateFromHydration,
}: {
  originalState: RootState;
  stateFromHydration: RootState;
}) {
  const stateToOverwrite: Partial<RootState> = {};

  // case: '고객지원 상세 관련' 페이지
  if (stateFromHydration.support?.GET_DETAIL) {
    stateToOverwrite.support = {
      ...originalState.support,
      GET_DETAIL: stateFromHydration.support?.GET_DETAIL,
    };
  }

  return stateToOverwrite;
}

export function* rootSaga() {
  yield all([
    authSaga(),
    assistanceSaga(),
    consolidationSaga(),
    oceanTicketSaga(),
    commonSaga(),
    supportSaga(),
    downloadSaga(),
    uploadSaga(),
    sampleSaga(),
    fulfillmentSaga(),
    fileSaga(),
    featuresSaga(),
  ]);
}
