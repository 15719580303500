import {
  getEmailValidation,
  validateValueLength,
  ValidationResult,
} from "@sellernote/_shared/src/utils/common/validation";

/** TODO: 추후에 서비스 전체 이메일 유효성 체크를 변경하는 작업시 수정예정 */
function validateEmail(email?: string): ValidationResult {
  if (!validateValueLength({ value: email, max: 80 }).result) {
    return validateValueLength({ value: email, max: 80 });
  }

  if (!getEmailValidation(email).result) {
    return getEmailValidation(email);
  }

  return { result: true };
}

export { validateEmail };
