import { createSelector } from "reselect";

import { InputValidationResult } from "@sellernote/_shared/src/types/common/common";
import { toFixedFloat } from "@sellernote/_shared/src/utils/common/number";
import {
  calculateCBM,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import { calculateRTonByWarehouse } from "@sellernote/_shared/src/utils/forwarding/consolidation";

import { RootState } from "../../store";

import { consolidationActions } from ".";

const areAllSourceAddressValid = createSelector(
  [
    (state: RootState) => state.consolidation.order.form.sellerList,
    (state: RootState) =>
      state.consolidation.order.findOptimalWareHouse.sourceAddressData,
  ],
  (sellerList, sourceAddressData) => {
    if (!sellerList || !sourceAddressData) {
      return false;
    }

    return sellerList.every((seller) => {
      const addressData = sourceAddressData[seller.key];

      if (addressData && addressData.validationStatus === "valid") {
        return true;
      }

      return false;
    });
  }
);

const areAllSellerHasAddress = createSelector(
  (state: RootState) => state.consolidation.order.form.sellerList,
  (sellerList) => {
    if (!sellerList?.length) return false;

    return sellerList.every((s) => !!s.address);
  }
);

const getStep1InputValidation = createSelector(
  (state: RootState) => state.consolidation.order.form,
  (form): InputValidationResult => {
    if (!(form.sellerList && form.sellerList.length > 0)) {
      return {
        status: "invalid",
        message: "판매자 수를 선택하세요",
      };
    }

    if (!form.originType) {
      return {
        status: "invalid",
        message: "출발지 유형을 선택하세요",
      };
    }

    if (!form.warehouse) {
      return {
        status: "invalid",
        message: "창고를 선택하세요",
      };
    }

    return { status: "valid" };
  }
);

const getStep2InputValidation = createSelector(
  (state: RootState) => state.consolidation.order.form,
  (form): InputValidationResult => {
    if (!form.destinationInlandZone) {
      return {
        status: "invalid",
        message: "최종 도착지를 선택하세요",
      };
    }

    if (!form.destinationInlandAddressDetail) {
      return {
        status: "invalid",
        message: "상세 내륙주소를 입력하세요",
      };
    }

    if (!form.stopOverPort) {
      return {
        status: "invalid",
        message: "경유지 항구를 선택하세요",
      };
    }

    return { status: "valid" };
  }
);

const getStep3InputValidation = createSelector(
  (state: RootState) => state.consolidation.order.form,
  (form): InputValidationResult => {
    if (!form.sellerList) {
      return {
        status: "invalid",
        message: "판매자 정보가 없습니다",
      };
    }

    let invalidSellerMessage = "";
    const hasInvalidSeller = form.sellerList.some((seller, sellerIndex) => {
      if (!seller || !seller.cargoList?.length) return true;

      if (!seller.cargoList?.length) {
        invalidSellerMessage = `판매자${sellerIndex + 1}의 화물정보가 없습니다`;
        return true;
      }

      const hasInvalidCargo = seller.cargoList.some((cargo, cargoIndex) => {
        const cargoErrorMessage = `판매자${sellerIndex + 1}의 ${
          cargoIndex + 1
        }번째 화물정보를 모두 입력해주세요`;

        if (
          !(
            cargo.name &&
            cargo.packagingType &&
            cargo.weight &&
            cargo.weightUnit &&
            cargo.isDangerous &&
            cargo.canStack
          )
        ) {
          invalidSellerMessage = cargoErrorMessage;
          return true;
        }

        if (seller.cargoType === "each") {
          if (
            !(
              cargo.horizontal &&
              cargo.vertical &&
              cargo.height &&
              cargo.sizeUnit &&
              cargo.quantity
            )
          ) {
            invalidSellerMessage = cargoErrorMessage;
            return true;
          }
        }

        if (seller.cargoType === "total") {
          if (!cargo.cbm) {
            invalidSellerMessage = cargoErrorMessage;
            return true;
          }
        }
      });

      return hasInvalidCargo;
    });
    if (hasInvalidSeller) {
      return {
        status: "invalid",
        message: invalidSellerMessage,
      };
    }

    if (!form.inlandTransportType) {
      return {
        status: "invalid",
        message: "내륙운송 유형을 선택해주세요",
      };
    }

    return { status: "valid" };
  }
);

const getIsReadyToCalculateCargoSum = createSelector(
  (state: RootState) => state.consolidation.order.form.sellerList,
  (sellerList) => {
    if (!sellerList?.length) {
      return false;
    }

    const allSellerAreReady = sellerList!.every((seller) => {
      return seller.cargoList.every((v) => {
        if (v.cbm && v.weight && v.weightUnit) {
          return true;
        }

        if (
          v.horizontal &&
          v.height &&
          v.vertical &&
          v.sizeUnit &&
          v.weight &&
          v.weightUnit &&
          v.quantity
        ) {
          return true;
        }

        return false;
      });
    });

    return allSellerAreReady;
  }
);

const getTotalWeightAsTon = createSelector(
  [
    getIsReadyToCalculateCargoSum,
    (state: RootState) => state.consolidation.order.form.sellerList,
  ],
  (isReadyForCalculation, sellerList) => {
    if (!isReadyForCalculation) {
      return 0;
    }

    let sum = 0;
    if (!sellerList?.length) {
      return sum;
    }

    sellerList.forEach((seller) => {
      seller.cargoList.forEach((v) => {
        const weightAsTon = toTon(v.weight!, v.weightUnit!);
        const subSum = v.quantity ? weightAsTon * v.quantity : weightAsTon;
        if (subSum) {
          sum += subSum;
        }
      });
    });

    return toFixedFloat(sum, 2) || 0;
  }
);

const getTotalWeightAsKg = createSelector(
  [
    getIsReadyToCalculateCargoSum,
    (state: RootState) => state.consolidation.order.form.sellerList,
  ],
  (isReadyForCalculation, sellerList) => {
    if (!isReadyForCalculation) {
      return 0;
    }

    let sum = 0;
    if (!sellerList?.length) {
      return sum;
    }

    sellerList.forEach((seller) => {
      seller.cargoList.forEach((v) => {
        const weightAsKg = toKg(v.weight!, v.weightUnit!);
        const subSum = v.quantity ? weightAsKg * v.quantity : weightAsKg;
        if (subSum) {
          sum += subSum;
        }
      });
    });

    return toFixedFloat(sum, 2) || 0;
  }
);

const getTotalCbm = createSelector(
  [
    getIsReadyToCalculateCargoSum,
    (state: RootState) => state.consolidation.order.form.sellerList,
  ],
  (isReadyForCalculation, sellerList) => {
    if (!isReadyForCalculation) {
      return 0;
    }
    let sum = 0;

    if (!sellerList?.length) {
      return sum;
    }

    sellerList!.forEach((seller) => {
      seller.cargoList.forEach((v) => {
        let subSum = 0;
        if (v.cbm) {
          subSum += v.cbm * (v.quantity || 1);
        } else {
          subSum =
            calculateCBM({
              type: "lcl",
              width: v.horizontal || 0,
              height: v.height || 0,
              depth: v.vertical || 0,
              sizeUnit: v.sizeUnit,
            })! * (v.quantity || 0);
        }

        sum += subSum;
      });
    });

    return toFixedFloat(sum, 2) || 0;
  }
);

const getTotalWm = createSelector(
  [
    getTotalWeightAsKg,
    getTotalCbm,
    (state: RootState) => state.consolidation.order.form.warehouse?.name,
  ],
  (totalWeightAsKg, totalCbm, warehouseNameKR) => {
    if (!(totalWeightAsKg && totalCbm)) {
      return 0;
    }

    const totalWeightAsTon = totalWeightAsKg / 1000;
    const result = calculateRTonByWarehouse({
      totalCbm,
      totalWeightAsTon,

      warehouseNameKR: warehouseNameKR || "",
    });
    return toFixedFloat(result, 2);
  }
);

const getTotalRton = createSelector(
  [
    getTotalCbm,
    getTotalWeightAsTon,
    (state: RootState) => state.consolidation.order.form.warehouse?.name,
  ],
  (totalCbm, totalWeightAsTon, warehouseNameKR) => {
    return calculateRTonByWarehouse({
      totalCbm,
      totalWeightAsTon,
      warehouseNameKR: warehouseNameKR || "",
    });
  }
);

/**
 * 인코텀즈를 유저가 선택하지 않고, 조건에 따라 인코텀즈가 자동으로 산정된다.
 */
const getIncoterms = createSelector(
  (state: RootState) => state.consolidation.order.form,
  (form) => {
    switch (form.originType) {
      case "inland": {
        return "EXW";
      }
      case "warehouse": {
        return "FCA";
      }
      default: {
        return "";
      }
    }
  }
);

const getLoadingQuotationDownloadLink = (state: RootState) => {
  return state.loading[
    consolidationActions.ORDER_GET_QUOTATION_DOWNLOAD_LINK.type
  ] as boolean;
};

export default {
  areAllSourceAddressValid,
  getStep1InputValidation,
  getStep2InputValidation,
  getStep3InputValidation,
  areAllSellerHasAddress,
  getTotalRton,
  getTotalWeightAsTon,
  getTotalWeightAsKg,
  getTotalWm,
  getTotalCbm,
  getIncoterms,

  getLoadingQuotationDownloadLink,
};
