import React from "react";

import { Incoterms, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidProjectStatus,
  BidStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  FbaOrderForm,
  FbaWareHousingMethod,
} from "@sellernote/_shared/src/types/forwarding/fba";
import { toFixedFloat } from "@sellernote/_shared/src/utils/common/number";
import {
  calculateCBM,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import { OrderNoteListItem } from "@sellernote/_sds-v1/src/components/order/OrderNote";
import { FBA } from "@sellernote/common-interfaces";

import { i18n, Trans } from "./i18n";

// TODO: 문구받으면 내용수정
export const ORDER_FAQ: { question: string; answer: React.ReactNode }[] = [
  {
    question: "청구되는 견적 기준은 어떻게 되나요?",
    answer: (
      <>
        오션티켓 서비스는 기본료와 할증료로 견적이 구분됩니다. <br />
        '기본료'는 화물의 부피/중량에 관계없이 청구되는 '국내 고정비'입니다.
        'OCEAN TICKET'은 화물 부피/중량에 따른 비용으로 '0.1 R.TON' 단위로
        청구되고 진행 구간마다 견적이 상이합니다.
        <br />
        <br />
        R.TON이란? REVENUE TON으로 해상운임을 산출하기 위해
        부피(세제곱미터=CBM)와 중량(톤=TON)을 비교하기 위한 단위입니다.
        <br />
        <br />
        예를 들어, 부피: 1x1x1m=1㎥, 중량: 0.5ton이라면 1과 0.5 중 1이 더 크기에
        1이 견적의 기준이 되고 단위는 R.TON으로 변경됩니다.
        <br />
        <br />
        기본료: 10만원, OCEAN TICKET: $5/0.1CBM이라면
        10만원(기본료)+$50(10*$5)으로 약 15만원의 확정가격으로 오션티켓 서비스를
        이용하실 수 있습니다.
      </>
    ),
  },
  {
    question: "업무의뢰 이후 프로세스는 어떻게 되나요?",
    answer: (
      <>
        업무 의뢰 이후 프로세스는 다음과 같습니다.
        <br />
        1. 수출자 정보 입력
        <br />
        - 화물 준비 일정 및 스케쥴을 수립하기 위해 수출자와 커뮤니케이션이
        필요합니다. [마이페이지] - [일반 운송 현황]에서 의뢰내역을 클릭하시고,
        수출자 정보를 입력해주세요.
        <br />
        <br />
        2. 화물 준비 및 스케쥴 확정
        <br />
        - 수출자의 화물 준비 일정에 맞춰 선적 스케쥴을 수립합니다. 수립 이후
        수입자(구매자)에게 일정을 안내하고 진행여부를 확정합니다.
        <br />
        <br />
        3. 운송, 통관 및 정산
        <br />
        - 한국 항구 도착 후, 쉽다가 의뢰고객에게 운송비를 청구합니다. 정산완료
        후 국내 통관절차를 진행합니다. 이후 통관이 마무리되면 최종도착지로
        운송합니다.
        <br />
        <br />
        조금 더 쉽게 프로세스를 위해 영상을 준비했습니다. 아래 링크를 클릭하시면
        영상으로 바로 이동합니다.
      </>
    ),
  },
  {
    question: "중국 이외에 타 국가 서비스 오픈 일정을 확인할 수 있나요?",
    answer: (
      <>
        수요가 많은 국가 및 항구를 기점으로 서비스를 진행하고 있습니다. 중국
        이외에 타 국가도 점진적으로 서비스를 확장할 예정입니다.
      </>
    ),
  },
  {
    question:
      "일정 및 의뢰내역 변경 등이 필요합니다. 상담원과 연결도 가능한가요?",
    answer: (
      <>
        네, 고객분들마다 전문 CS 상담원이 배정됩니다. 궁금하신 사항은 유선 혹은
        우하단 실시간 상담하기를 통해 문의 부탁드립니다.
      </>
    ),
  },
];

/**
 * 주의사항
 */
export const NOTE_LIST: OrderNoteListItem[] = [
  {
    clause: (
      <>
        미국 수입통관 시 발생하는 통관 이슈는 수출자 측 책임이며, 쉽다는
        책임지지 않습니다. <br />
        (우려되는 사항이 있으실 경우 사전 상담주시면 미국 관세사 측과 확인하여
        안내드립니다.)
      </>
    ),
  },
  {
    clause:
      "한국/미국 세관 무작위 검사 적발 시, 세관검사 비용이 추가발생되며 실비로 청구됩니다.",
  },
  {
    clause: "거래명세서는 실제 선적된 부피/중량에 따라 청구됩니다.",
  },
  {
    clause:
      "위험물, 2단적재 등 특수 케이스의 경우 선적이 불가할 수 있고 견적이 상이할 수 있습니다. (단, 해당 내용은 선적 전 사전 고지합니다.)",
  },
  {
    clause:
      "미국 3PL 창고보관료는 1PLT(파렛트) 기준으로 적용되며 기본 1개월 '$30/1PLT'이 청구됩니다. (박스 포장을 통해 수출하는 경우에도 3PL 창고에서 파렛트 단위로 보관됩니다. 박스 부피가 파렛트 적재 가능 부피를 초과하는 경우 2파렛트 기준으로 견적이 청구됩니다.",
  },
  {
    clause:
      "화물 분실/파손에 대해 책임지지 않습니다. 우려되시는 경우 '적하보험' 가입 신청 부탁드립니다.",
  },
  {
    clause:
      "한국 쉽다(ShipDa)창고로 상품을 발송 전, 사전에 포장별 아마존 바코드 처리 해주셔야 하며 미부착/오기 등으로 인한 피해는 쉽다가 책임지지 않습니다.",
  },
];

/**
 * 창고 옵션리스트
 */
export const WareHouseOptionList = [
  {
    label: formatWareHouse(FBA.WareHousesFBA.ONT8),
    value: FBA.WareHousesFBA.ONT8,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.SMF3),
    value: FBA.WareHousesFBA.SMF3,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.LGB),
    value: FBA.WareHousesFBA.LGB,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.MDW),
    value: FBA.WareHousesFBA.MDW,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.IVSE),
    value: FBA.WareHousesFBA.IVSE,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.IVSE2),
    value: FBA.WareHousesFBA.IVSE2,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.CVG3),
    value: FBA.WareHousesFBA.CVG3,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.FTW2),
    value: FBA.WareHousesFBA.FTW2,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.ITX),
    value: FBA.WareHousesFBA.ITX,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.CLT2),
    value: FBA.WareHousesFBA.CLT2,
  },
  {
    label: formatWareHouse(FBA.WareHousesFBA.MEM),
    value: FBA.WareHousesFBA.MEM,
  },
];

/**
 * 운송수단 옵션리스트
 */
export const TransportTypeOptionList = [
  {
    label: formatTransportType(FBA.DeliveryInUSA.UPS),
    value: FBA.DeliveryInUSA.UPS,
  },
  {
    label: formatTransportType(FBA.DeliveryInUSA.LTL),
    value: FBA.DeliveryInUSA.LTL,
  },
  {
    label: formatTransportType(FBA.DeliveryInUSA.FTL),
    value: FBA.DeliveryInUSA.FTL,
  },
];

/**
 * FBA용 인코텀즈 옵션리스트
 */
export const FbaIncotermsOptionList: { label: string; value: Incoterms }[] = [
  { label: "DDP", value: "DDP" },
];

export function getWareHousingMethodString(
  wareHousingMethod?: FbaWareHousingMethod
) {
  switch (wareHousingMethod) {
    case "direct": {
      return "직접 입고";
    }
    case "pickUp": {
      return "픽업 요청";
    }
  }
}

/**
 * @param {*} form
 */
export function getTotalWeightAsTon(form: Partial<FbaOrderForm>) {
  if (form.cargoInfoType === "lclTotal") {
    if (!form.cargoInfoLclTotal) {
      return;
    }

    let weightAsTon;

    if (form.cargoInfoLclTotal.weight && form.cargoInfoLclTotal.weightUnit) {
      weightAsTon = toTon(
        form.cargoInfoLclTotal.weight,
        form.cargoInfoLclTotal.weightUnit
      );
    }

    return weightAsTon;
  }

  if (form.cargoInfoType === "lclEach") {
    if (!isAllCargoInfoLclEachFilled(form)) {
      return;
    }

    let totalWeightAsTon = 0;

    form.cargoInfoLclEach!.forEach((v, i) => {
      const weightAsTon = toTon(v.weight!, v.weightUnit!);
      const subTotalWeight = weightAsTon * v.quantity!;

      totalWeightAsTon += subTotalWeight;
    });

    return totalWeightAsTon;
  }
}

export function isAllCargoInfoLclEachFilled(form: Partial<FbaOrderForm>) {
  if (!form.cargoInfoLclEach || form.cargoInfoLclEach.length <= 0) {
    return false;
  }

  if (
    !form.cargoInfoLclEach.every((v) => {
      return (
        v.name &&
        v.horizontal &&
        v.vertical &&
        v.height &&
        v.weight &&
        v.quantity
      );
    })
  ) {
    return false;
  }

  return true;
}

export function getTotalContainerCbm(form: Partial<FbaOrderForm>) {
  if (form.cargoInfoType === "lclTotal") {
    if (!form.cargoInfoLclTotal) {
      return null;
    }

    return Number(form.cargoInfoLclTotal.cbm);
  }

  if (form.cargoInfoType === "lclEach") {
    if (!isAllCargoInfoLclEachFilled(form)) {
      return null;
    }

    let totalCbm = 0;

    form.cargoInfoLclEach!.forEach((v) => {
      const subTotalCbm =
        calculateCBM({
          type: "lcl",
          width: v.horizontal!,
          height: v.height!,
          depth: v.vertical!,
          sizeUnit: v.sizeUnit!,
        })! * v.quantity!;

      totalCbm += subTotalCbm;
    });

    return toFixedFloat(totalCbm, 2);
  }
}

export function getFbaStartPoint(params: {
  startCountryCode?: string;
  startCountryName?: string;
  startPort?: Port;
  startViaPort?: Port;
}) {
  if (!params.startCountryCode || (!params.startViaPort && !params.startPort)) {
    return "";
  }

  if (params.startPort) {
    return `${params.startCountryName} ${
      params.startPort.name || params.startPort.nameEN
    }`;
  }

  if (params.startViaPort) {
    return (
      <>
        {params.startCountryName} <Trans i18nKey="common:inland" i18n={i18n} />
      </>
    );
  }

  return "";
}

/**
 * 선적스케쥴을 업데이트 할 수 있는 단계인지 체크
 */
export function canUpdateShipSchedule(
  status: BidStatus,
  projectStatus: BidProjectStatus
) {
  if (status === "inProgress") {
    const validProjectStatuses: BidProjectStatus[] = ["scheduling"];

    if (validProjectStatuses.includes(projectStatus)) {
      return true;
    }
  }
  return false;
}

export function formatTransportType(type?: FBA.DeliveryInUSA) {
  switch (type) {
    case FBA.DeliveryInUSA.UPS: {
      return "Amazon-Partnered Carrier(UPS)";
    }
    case FBA.DeliveryInUSA.LTL: {
      return "LTL";
    }
    case FBA.DeliveryInUSA.FTL: {
      return "FTL";
    }
    default: {
      return "-";
    }
  }
}

export function formatWareHouse(wareHouse?: FBA.WareHousesFBA) {
  switch (wareHouse) {
    case FBA.WareHousesFBA.ONT8: {
      return "ONT8 [서부]";
    }
    case FBA.WareHousesFBA.SMF3: {
      return "SMF3 [서부]";
    }
    case FBA.WareHousesFBA.LGB: {
      return "LGB [서부]";
    }
    case FBA.WareHousesFBA.MDW: {
      return "MDW [중부]";
    }
    case FBA.WareHousesFBA.IVSE: {
      return "IVSE [중부]";
    }
    case FBA.WareHousesFBA.IVSE2: {
      return "IVSE2 [중동부]";
    }
    case FBA.WareHousesFBA.CVG3: {
      return "CVG3 [중동부]";
    }
    case FBA.WareHousesFBA.FTW2: {
      return "FTW2 [남부(텍사스)]";
    }
    case FBA.WareHousesFBA.ITX: {
      return "ITX [남부(텍사스)]";
    }
    case FBA.WareHousesFBA.CLT2: {
      return "CLT2 [동부 & 남동부]";
    }
    case FBA.WareHousesFBA.MEM: {
      return "MEM [동부 & 남동부]";
    }
    default: {
      return "-";
    }
  }
}
