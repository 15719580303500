import { takeLatest } from "redux-saga/effects";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import { BooleanString } from "@sellernote/_shared/src/types/common/common";
import { DownloadTargetType } from "@sellernote/_shared/src/types/common/download";

import { createRequestSaga } from "../../services/legacyRequest";

// Action Type
const prefix = "download/";
export const actionType = {
  INIT: `${prefix}INIT`,

  INIT_GET_LINK: `${prefix}INIT_GET_LINK`,
  GET_LINK: `${prefix}GET_LINK`,
  GET_LINK_SUCCESS: `${prefix}GET_LINK_SUCCESS`,
  GET_LINK_FAILURE: `${prefix}GET_LINK_FAILURE`,

  UPDATE_GET_LINK_NEED_INITIAL_DOWNLOAD: `${prefix}UPDATE_GET_LINK_NEED_INITIAL_DOWNLOAD`,
};

// Actions
export const actions = {
  init: () => {
    return { type: actionType.INIT };
  },

  getLink: (payload: any, targetType: DownloadTargetType | string) => {
    const isUsingFileKey =
      targetType === "bidInvoice" ||
      targetType === "BLInvoice" ||
      targetType === "CIAndPLInvoice" ||
      targetType === "COInvoice" ||
      targetType === "etcInvoice" ||
      targetType === "certificate" ||
      targetType === "importDeclaration" ||
      targetType === "brn" ||
      targetType === "importTax" ||
      typeof targetType === "string";

    if (isUsingFileKey) {
      payload.fileKey = payload.key;
    }

    payload.key = targetType;

    return { type: actionType.GET_LINK, payload };
  },
  updateLinkNeedInitialDownload: (
    status: BooleanString,
    targetType: DownloadTargetType | string
  ) => {
    const payload = {
      status,
      key: targetType,
    };

    return {
      type: actionType.UPDATE_GET_LINK_NEED_INITIAL_DOWNLOAD,
      payload,
    };
  },
  initGetLink: (targetType: DownloadTargetType | string) => {
    return { type: actionType.INIT_GET_LINK, key: targetType };
  },
};

// Sagas
const sagas = {
  getLinkSaga: createRequestSaga(actionType.GET_LINK, (payload: any) => {
    switch (payload.key as DownloadTargetType) {
      case "estimateFromEmail": {
        return sendRequest({
          method: "put",
          path: `/searchEstimate/download/${payload.id}`,
          data: {
            estimateLogId: payload.estimateLogId,
            freightType: payload.freightType,
          },
          apiType: "ShipdaDefaultNew",
        });
      }

      case "realtimeBidQuotation": {
        return sendRequest({
          method: "put",
          path: "/searchEstimate/download",
          data: payload,
          apiType: "ShipdaDefaultNew",
        });
      }

      case "bidDetailQutaotion": {
        return sendRequest({
          method: "get",
          path: `/bid/download/${payload.id}`,
          apiType: "ShipdaDefaultNew",
        });
      }

      case "bidInvoice":
      case "BLInvoice":
      case "CIAndPLInvoice":
      case "COInvoice":
      case "etcInvoice":
      case "certificate":
      case "importDeclaration":
      case "brn":
      case "importTax":
      case "purchaseOrder":
      case "purchaseOrderEtc": {
        return sendRequest({
          method: "get",
          path: "file/download",
          params: { key: payload.fileKey },
          apiType: "ShipdaDefaultNew",
        });
      }

      case "importProcessGuideBook":
      case "consolidationGuideBook":
      case "oceanTicketGuideBook":
      case "serviceGuide":
      case "allServiceGuide":
      case "serviceGuideBook":
      case "fulfillmentIntroduction":
      case "fulfillmentEstimation":
      case "fulfillmentGuide":
      case "fulfillmentEventEstimation":
      case "fulfillmentEventGuide":
      case "boful": {
        return sendRequest({
          method: "get",
          path: "customerCare/guide",
          params: payload,
          apiType: "ShipdaDefaultNew",
        });
      }

      default:
        return sendRequest({
          method: "get",
          path: "file/download",
          params: { key: payload.fileKey },
          apiType: "ShipdaDefaultNew",
        });
    }
  }),
};
export function* downloadSaga() {
  yield takeLatest(actionType.GET_LINK, sagas.getLinkSaga);
}
