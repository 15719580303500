import selectors from "./selectors";
import slice, { saga } from "./slice";

const consolidationReducer = slice.reducer;
const consolidationActions = slice.actions;
const consolidationSaga = saga;
const consolidationSelectors = selectors;

export {
  consolidationReducer,
  consolidationActions,
  consolidationSaga,
  consolidationSelectors,
};
