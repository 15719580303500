import { createSlice, original, PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  ORDER_GET_INLAND_COST_REQ,
  ORDER_GET_INLAND_COST_RES,
  ORDER_GET_OPTIMAL_WAREHOUSE_REQ,
  ORDER_GET_OPTIMAL_WAREHOUSE_RES,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_REQ,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_RES,
  ORDER_GET_QUOTATION_REQ,
  ORDER_GET_QUOTATION_RES,
  ORDER_GET_STOP_OVER_PORT_REQ,
  ORDER_GET_STOP_OVER_PORT_RES,
  ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED_REQ,
  ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED_RES,
  ORDER_SEND_REQUEST_REQ,
  ORDER_SEND_REQUEST_RES,
  ORDER_VALIDATE_ADDRESS_REQ,
  ORDER_VALIDATE_ADDRESS_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/consolidation";
import { createReducerWithSagaBundleForShipDa } from "@sellernote/_shared/src/services/request";
import {
  ApiResponseState,
  BooleanString,
} from "@sellernote/_shared/src/types/common/common";
import {
  ConsolidationOrder,
  ConsolidationOrderFormSellerCargoItem,
  ConsolidationOrderStateFromReorder,
} from "@sellernote/_shared/src/types/forwarding/consolidation";
import { getExpiredAt } from "@sellernote/_shared/src/utils/common/date";
import { getUpdatedObject } from "@sellernote/_shared/src/utils/common/etc";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { loadingActions } from "../loading";

export interface ConsolidationState {
  order: ConsolidationOrder;

  UPDATE_EXPORTER_INFO: {
    [K in string]?: Partial<ApiResponseState<any>>;
  };
  UPDATE_EXPORTER_INFO_LIST: Partial<ApiResponseState<any>>;
}

export const cargoListItemDefaultState: Partial<ConsolidationOrderFormSellerCargoItem> =
  {
    sizeUnit: "cm",
    weightUnit: "KG",
    canStack: "yes",
    isDangerous: "no",
  };
export const cargoListInitialState: ConsolidationOrderFormSellerCargoItem[] = [
  {
    key: 0,
    ...cargoListItemDefaultState,
  } as ConsolidationOrderFormSellerCargoItem,
];

const initialState: ConsolidationState = {
  order: {
    step: 1,
    form: {},
    ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED: apiResponseInitialState,
    ORDER_GET_STOP_OVER_PORT: apiResponseInitialState,
    ORDER_GET_INLAND_COST: apiResponseInitialState,
    ORDER_GET_QUOTATION: apiResponseInitialState,
    findOptimalWareHouse: { sourceAddressData: {} },
    ORDER_GET_QUOTATION_DOWNLOAD_LINK: apiResponseInitialState,
    ORDER_SEND_REQUEST: apiResponseInitialState,
  },
  UPDATE_EXPORTER_INFO: {},
  UPDATE_EXPORTER_INFO_LIST: apiResponseInitialState,
};

const testState = {} as unknown as ConsolidationState;

const SLICE_NAME = "consolidation";

const ORDER_GET_STOP_OVER_PORT = createReducerWithSagaBundleForShipDa<
  "ORDER_GET_STOP_OVER_PORT",
  ORDER_GET_STOP_OVER_PORT_REQ,
  ORDER_GET_STOP_OVER_PORT_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "ORDER_GET_STOP_OVER_PORT",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/consolidations/port/destination",
      params: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  customSuccessHandler: ({ state, actionTypeKey, action }) => {
    const data: ORDER_GET_STOP_OVER_PORT_RES = (action.payload || []).map(
      (v: any) => {
        return {
          ...v,
          lat: v.endPort?.lat,
          lng: v.endPort?.lng,
          name: v.endPort?.name,
          nameEN: v.endPort?.nameEN,
        };
      }
    );

    state.order.ORDER_GET_STOP_OVER_PORT = {
      data,
      status: "SUCCESS",
    };
  },
  customFailureHandler: ({ state, actionTypeKey, action }) => {
    state.order.ORDER_GET_STOP_OVER_PORT = {
      data: undefined,
      status: "FAILURE",
      failureInfo: action.payload,
    };
  },
  customInitHandler: ({ state, actionTypeKey }) => {
    state.order.ORDER_GET_STOP_OVER_PORT = apiResponseInitialState;
  },
  loadingActions,
});

const ORDER_GET_QUOTATION = createReducerWithSagaBundleForShipDa<
  "ORDER_GET_QUOTATION",
  ORDER_GET_QUOTATION_REQ,
  ORDER_GET_QUOTATION_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  parentStateName: "order",
  actionTypeKey: "ORDER_GET_QUOTATION",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/searchEstimate/consolidation",
      params: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  loadingActions,
});

const ORDER_GET_OPTIMAL_WAREHOUSE = createReducerWithSagaBundleForShipDa<
  "ORDER_GET_OPTIMAL_WAREHOUSE",
  ORDER_GET_OPTIMAL_WAREHOUSE_REQ,
  ORDER_GET_OPTIMAL_WAREHOUSE_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  parentStateName: "order.findOptimalWareHouse",
  actionTypeKey: "ORDER_GET_OPTIMAL_WAREHOUSE",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/consolidations/wareHouse",
      params: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  loadingActions,
});

const ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED =
  createReducerWithSagaBundleForShipDa<
    "ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED",
    ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED_REQ,
    ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED_RES,
    ConsolidationState
  >({
    sliceName: SLICE_NAME,
    parentStateName: "order",
    actionTypeKey: "ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED",
    getRequestOptions: (payload) => {
      return {
        method: "get",
        path: "/consolidations/addressValidation/warehouses",
        params: payload,
        apiType: "ShipdaDefaultNew",
      };
    },
    loadingActions,
  });

const ORDER_GET_INLAND_COST = createReducerWithSagaBundleForShipDa<
  "ORDER_GET_INLAND_COST",
  ORDER_GET_INLAND_COST_REQ,
  ORDER_GET_INLAND_COST_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  parentStateName: "order",
  actionTypeKey: "ORDER_GET_INLAND_COST",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/searchEstimate/inlandCost",
      params: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  loadingActions,
});

const ORDER_SEND_REQUEST = createReducerWithSagaBundleForShipDa<
  "ORDER_SEND_REQUEST",
  ORDER_SEND_REQUEST_REQ,
  ORDER_SEND_REQUEST_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  parentStateName: "order",
  actionTypeKey: "ORDER_SEND_REQUEST",
  getRequestOptions: (payload) => {
    return {
      method: "post",
      path: "/bid/register",
      data: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  loadingActions,
});

const ORDER_GET_QUOTATION_DOWNLOAD_LINK = createReducerWithSagaBundleForShipDa<
  "ORDER_GET_QUOTATION_DOWNLOAD_LINK",
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_REQ,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  parentStateName: "order",
  actionTypeKey: "ORDER_GET_QUOTATION_DOWNLOAD_LINK",
  getRequestOptions: (payload) => {
    return {
      method: "put",
      path: `/consolidations/cost/download/${payload.consolidationDataId}`,
      data: { quotationInfoForDownload: payload.quotationInfoForDownload },
      apiType: "ShipdaDefaultNew",
    };
  },
  customSuccessHandler: ({ state, actionTypeKey, action }) => {
    const duration = action.payload?.preSignedUrl?.expirationTime;

    if (!duration) return;

    state.order.ORDER_GET_QUOTATION_DOWNLOAD_LINK = {
      data: action.payload,
      status: "SUCCESS",
      needInitialDownload: "yes",
      expiredAt: getExpiredAt(duration - 60), // buffer로 1분을 추가
    };
  },
  loadingActions,
});

const ORDER_VALIDATE_ADDRESS = createReducerWithSagaBundleForShipDa<
  "ORDER_VALIDATE_ADDRESS",
  ORDER_VALIDATE_ADDRESS_REQ,
  ORDER_VALIDATE_ADDRESS_RES,
  ConsolidationState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "ORDER_VALIDATE_ADDRESS",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/consolidations/coordinates",
      params: payload,
      apiType: "ShipdaDefaultNew",
    };
  },
  customActionBeforeSaga: ({ state, action }) => {
    const target =
      state.order.findOptimalWareHouse.sourceAddressData[action.key! as number];
    if (target) {
      target.validationStatus = "loading";
    }
  },
  customSuccessHandler: ({ state, actionTypeKey, action }) => {
    const target =
      state.order.findOptimalWareHouse.sourceAddressData[action.key! as number];

    if (target) {
      if (action.payload?.lat && action.payload?.lng) {
        target.validationStatus = "valid";
        target.lat = action.payload.lat;
        target.lng = action.payload.lng;
      } else {
        target.validationStatus = "invalid";
        target.lat = undefined;
        target.lng = undefined;
      }
    }
  },
  customFailureHandler: ({ state, actionTypeKey, action }) => {
    const target =
      state.order.findOptimalWareHouse.sourceAddressData[action.key! as number];

    if (target) {
      if (action.payload?.code === 404 || action.payload?.code === 400) {
        target.validationStatus = "invalid";
      } else {
        target.validationStatus = "error";
      }
      target.lat = undefined;
      target.lng = undefined;
    }
  },
  customInitHandler: ({ state, actionTypeKey, action }) => {
    const target =
      state.order.findOptimalWareHouse.sourceAddressData[
        (action.payload?.key ?? 0) as number
      ];

    if (target) {
      target.lat = undefined;
      target.lng = undefined;
      target.validationStatus = undefined;
      target.validateAddress = apiResponseInitialState;
    }
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    ...ORDER_GET_OPTIMAL_WAREHOUSE.reducerBundle,
    ...ORDER_VALIDATE_ADDRESS.reducerBundle,
    ...ORDER_GET_STOP_OVER_PORT.reducerBundle,
    ...ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED.reducerBundle,
    ...ORDER_GET_INLAND_COST.reducerBundle,
    ...ORDER_GET_QUOTATION.reducerBundle,
    ...ORDER_SEND_REQUEST.reducerBundle,
    ...ORDER_GET_QUOTATION_DOWNLOAD_LINK.reducerBundle,

    ORDER_INIT: (state) => {
      state.order = initialState.order;
    },

    ORDER_INIT_AS_RE_ORDER: (state) => {
      if (state.order.stateFromReorder) {
        state.order.step = state.order.stateFromReorder.step;
        state.order.form = {
          ...state.order.form,
          ...state.order.stateFromReorder.form,
        };
        state.order.findOptimalWareHouse =
          state.order.stateFromReorder.findOptimalWareHouse;
        state.order.stateFromReorder = undefined;
      }
    },

    ORDER_UPDATE_STATE_FROM_RE_ORDER: {
      prepare: (reOrderState: ConsolidationOrderStateFromReorder) => {
        return {
          payload: reOrderState,
        };
      },
      reducer: (
        state,
        action: PayloadAction<ConsolidationOrderStateFromReorder>
      ) => {
        state.order.stateFromReorder = action.payload;
      },
    },

    ORDER_UPDATE_STEP: {
      prepare: (step: number, isCameBackFromNextStep?: boolean) => {
        return {
          payload: { step, isCameBackFromNextStep },
        };
      },
      reducer: (
        state,
        action: PayloadAction<{
          step: number;
          isCameBackFromNextStep?: boolean;
        }>
      ) => {
        state.order.step = action.payload.step;
        state.order.isCameBackFromNextStep =
          action.payload.isCameBackFromNextStep;
      },
    },

    ORDER_UPDATE_STEP_ON_INPUT_VALIDATION: {
      prepare: (step: number) => {
        return {
          payload: step,
        };
      },
      reducer: (state, action: PayloadAction<number>) => {
        state.order.stepOnInputValidation = action.payload;
      },
    },

    ORDER_UPDATE_FORM_ITEM: {
      prepare: (key: string, value: any) => {
        return {
          payload: { key, value },
        };
      },
      reducer: (state, action: PayloadAction<{ key: string; value: any }>) => {
        state.order.form = getUpdatedObject(
          state.order.form!,
          action.payload.key,
          action.payload.value
        );

        // 판매자 수가 바뀔때
        if (
          state.order.form.sellerList?.length !==
          original(state)?.order.form.sellerList?.length
        ) {
          if (state.order.form.sellerList) {
            state.order.form.sellerList.forEach((v) => {
              v.cargoList = cargoListInitialState;

              state.order.findOptimalWareHouse.sourceAddressData[v.key] = {
                address: "",
              };
            });
          }

          state.order.form.originType = undefined;
        }

        if (
          state.order.form.destinationInlandZone?.id !==
          original(state)?.order.form.destinationInlandZone?.id
        ) {
          state.order.form.destinationInlandAddressDetail = undefined;
          state.order.form.stopOverPort = undefined;
        }

        if (
          state.order.form.originType !== original(state)?.order.form.originType
        ) {
          state.order.form.warehouse = undefined;

          state.order.form.sellerList?.forEach((v) => {
            v.address = undefined;
          });
        }

        state.order.form.sellerList?.forEach((seller, i) => {
          const originSeller =
            original(state)?.order.form.sellerList &&
            original(state)?.order.form.sellerList![i];

          // cargoType이 바뀔 때 cargoList를 초기화
          if (seller.cargoType !== originSeller?.cargoType) {
            seller.cargoList = cargoListInitialState;
          }
        });
      },
    },

    /**
     * 판매자 주소의 경우 의존하는 state조건이 복잡하여 ORDER_UPDATE_FORM_ITEM과 별개로 만듦
     */
    ORDER_UPDATE_SELLER_ADDRESS: {
      prepare: (sellerIndex: number, address: string) => {
        return {
          payload: { sellerIndex, address },
        };
      },
      reducer: (
        state,
        action: PayloadAction<{
          sellerIndex: number;
          address: string;
        }>
      ) => {
        const isSellerExist =
          state.order.form.sellerList &&
          state.order.form.sellerList[action.payload.sellerIndex];
        if (!isSellerExist) return;

        state.order.form.sellerList![action.payload.sellerIndex].address =
          action.payload.address;

        // 판매자의 주소가 바뀌면 창고목록도 초기화 & 선택한 창고도 초기화
        state.order.ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED =
          apiResponseInitialState;
        state.order.form.warehouse = undefined;
      },
    },

    ORDER_UPDATE_SOURCE_ADDRESS_DATA: {
      prepare: (key: number, value: string) => {
        return {
          payload: { key, value },
        };
      },
      reducer: (
        state,
        action: PayloadAction<{ key: number; value: string }>
      ) => {
        const target =
          state.order.findOptimalWareHouse.sourceAddressData[
            action.payload.key
          ];

        if (target) {
          target.address = action.payload.value;
        }
      },
    },

    UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD: {
      prepare: (payload: { status: BooleanString }) => {
        return {
          payload,
        };
      },

      reducer: (state, action: PayloadAction<{ status: BooleanString }>) => {
        state.order.ORDER_GET_QUOTATION_DOWNLOAD_LINK.needInitialDownload =
          action.payload.status;
      },
    },
  },
});

function* saga() {
  yield takeLatest(
    slice.actions.ORDER_GET_QUOTATION_DOWNLOAD_LINK,
    ORDER_GET_QUOTATION_DOWNLOAD_LINK.saga
  );
  yield takeLatest(
    slice.actions.ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED,
    ORDER_GET_WAREHOUSE_LIST_WITH_SUGGESTED.saga
  );
  yield takeEvery(
    slice.actions.ORDER_VALIDATE_ADDRESS,
    ORDER_VALIDATE_ADDRESS.saga
  );
  yield takeLatest(
    slice.actions.ORDER_GET_OPTIMAL_WAREHOUSE,
    ORDER_GET_OPTIMAL_WAREHOUSE.saga
  );
  yield takeLatest(
    slice.actions.ORDER_GET_STOP_OVER_PORT,
    ORDER_GET_STOP_OVER_PORT.saga
  );
  yield takeLatest(
    slice.actions.ORDER_GET_INLAND_COST,
    ORDER_GET_INLAND_COST.saga
  );
  yield takeLatest(slice.actions.ORDER_GET_QUOTATION, ORDER_GET_QUOTATION.saga);
  yield takeLatest(slice.actions.ORDER_SEND_REQUEST, ORDER_SEND_REQUEST.saga);
}

export default slice;

export { saga };
