import { takeLatest } from "redux-saga/effects";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import {
  ApiResponseState,
  BooleanString,
} from "@sellernote/_shared/src/types/common/common";
import { UserInfo } from "@sellernote/_shared/src/types/common/user";
import {
  AssistanceForm,
  AssistanceStep,
  ProductsInfoItem,
  QuotationInfo,
  RequestPayloadForRealtimeQuotation,
} from "@sellernote/_shared/src/types/forwarding/assistance";
import { SendRequestPayloadProductsInfoItem } from "@sellernote/_shared/src/types/forwarding/bid";
import { RealtimeQuotationLCLGeneral } from "@sellernote/_shared/src/types/forwarding/quotation";
import {
  calculateRTon,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import { getCurrencyRecordForBidRegisterPayload } from "@sellernote/_shared/src/utils/forwarding/bid";
import { ServiceType } from "@sellernote/common-interfaces/dist/bid";

import { createRequestSaga } from "../../services/legacyRequest";
import { getDestinationAddress, getZoneId } from "../../utils/bid";

// Action Type
const prefix = "assistance/";
export const actionType = {
  INIT_ORDER: `${prefix}INIT_ORDER`,

  ORDER_UPDATE_FORM_ITEM: `${prefix}ORDER_UPDATE_FORM_ITEM`,
  ORDER_UPDATE_CHAT_INDEX: `${prefix}ORDER_UPDATE_CHAT_INDEX`,
  ORDER_UPDATE_STEP_INDEX: `${prefix}ORDER_UPDATE_STEP_INDEX`,

  ORDER_GET_REALTIME_QUOTATION: `${prefix}ORDER_GET_REALTIME_QUOTATION`,
  ORDER_GET_REALTIME_QUOTATION_SUCCESS: `${prefix}ORDER_GET_REALTIME_QUOTATION_SUCCESS`,
  ORDER_GET_REALTIME_QUOTATION_FAILURE: `${prefix}ORDER_GET_REALTIME_QUOTATION_FAILURE`,
  INIT_ORDER_GET_REALTIME_QUOTATION: `${prefix}INIT_ORDER_GET_REALTIME_QUOTATION`,

  ORDER_SEND_REQUEST: `${prefix}ORDER_SEND_REQUEST`,
  ORDER_SEND_REQUEST_SUCCESS: `${prefix}ORDER_SEND_REQUEST_SUCCESS`,
  ORDER_SEND_REQUEST_FAILURE: `${prefix}ORDER_SEND_REQUEST_FAILURE`,
  INIT_ORDER_SEND_REQUEST: `${prefix}INIT_ORDER_SEND_REQUEST`,

  ORDER_GET_QUOTATION_DOWNLOAD_LINK: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK`,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS`,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE`,
  INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK: `${prefix}INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK`,

  UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD: `${prefix}UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD`,
};

// Actions
export const actions = {
  initOrder: () => {
    return { type: actionType.INIT_ORDER };
  },

  updateStepIndex: (nextStep: number) => {
    return { type: actionType.ORDER_UPDATE_STEP_INDEX, payload: nextStep };
  },
  updateFormItem: (key: string, value: any) => {
    return { type: actionType.ORDER_UPDATE_FORM_ITEM, payload: { key, value } };
  },
  updateChatIndex: (step: AssistanceStep, value: number) => {
    return {
      type: actionType.ORDER_UPDATE_CHAT_INDEX,
      payload: { step, value },
    };
  },
  initSendRequest: () => {
    return { type: actionType.INIT_ORDER_SEND_REQUEST };
  },
  sendRequest: (
    form: AssistanceForm,
    realtimeQuotation: ApiResponseState<RealtimeQuotationLCLGeneral>,
    userInfo: UserInfo
  ) => {
    if (!form) {
      return;
    }

    const hasQuotation = Boolean(
      realtimeQuotation &&
        realtimeQuotation.status === "SUCCESS" &&
        realtimeQuotation.data &&
        realtimeQuotation.data.cost
    );

    const isFixedQuotation =
      hasQuotation && !realtimeQuotation.data?.isTempPrice;

    const weightAsTon = toTon(form.weight, "KG");
    const rTon = calculateRTon(form.cbm, weightAsTon);

    const productsInfo: ProductsInfoItem[] = [
      {
        name: form.name,
        weight: weightAsTon,
        weightUnit: "TON",
        packingType: "boxes",
        isDouble: true,
        isDangerous: false,
        cbm: Number(form.cbm),
        unitSupply: Number(rTon),
        quantity: 1,
      },
    ];

    const commonQuotationInfo: QuotationInfo | undefined = (() => {
      if (!hasQuotation) return;

      // hasQuotation체크할때 RealtimeQuotationLCLGeneral임이 보장됨
      const quotationData =
        realtimeQuotation.data as RealtimeQuotationLCLGeneral;

      return {
        userId: userInfo?.id,
        forwarderId: quotationData.fare?.forwarderId,
        startCountry: form.departurePort.country,
        startPortId: form.departurePort.id,
        endCountry: APP_REGION!,
        endPortId: form.destinationPort.id,
        endZoneId: getZoneId({
          destinationType: "inland",
          destinationInlandZone: form.destinationInlandZone,
          destinationInlandMap: form.destinationInlandMap,
        })!,
        incoterms: "FOB",
        freightType: "LCL",
        volumn: "", // 그냥 빈 string으로 보내라고함
        liner: quotationData.fare?.liner,
        exchangeRate: quotationData.exchangeRate,
        totalPrice: quotationData.cost,
        freightFee: quotationData.freightFee,
        domesticFee: quotationData.domesticFee,
        otherFee: quotationData.otherFee,
        taxFee: quotationData.taxFee,
        inlandFee: quotationData.inlandFee,
        isTransit: quotationData.fare?.transitType
          ? quotationData.fare.transitType !== "direct"
          : undefined,
        expiredAt: quotationData.fare?.expiredAt,
        currency: getCurrencyRecordForBidRegisterPayload({
          currency: quotationData.currency,
          exchangeRate: quotationData.exchangeRate,
        }),
      };
    })();

    const payload: RequestPayloadForRealtimeQuotation = {
      serviceType: ServiceType.General,
      isFixed: isFixedQuotation,
      type: "forwarder",
      freightType: "LCL",
      transportType: "sea",
      price: isFixedQuotation ? realtimeQuotation.data?.cost : undefined,
      incoterms: "FOB",
      startCountry: form.departurePort.country,
      startPortId: form.departurePort.id,
      startType: "sea",
      endCountry: APP_REGION!,
      endViaPortId: form.destinationPort.id,
      zoneId: getZoneId({
        destinationType: "inland",
        destinationInlandZone: form.destinationInlandZone,
        destinationInlandMap: form.destinationInlandMap,
      })!,
      endAddress: getDestinationAddress({
        destinationType: "inland",
        destinationInlandZone: form.destinationInlandZone,
        destinationInlandEndAddress: form.destinationInlandEndAddress,
        destinationInlandMap: form.destinationInlandMap,
      })!,
      endAddressDetail: form.destinationInlandAddressDetail,
      endType: "inland",
      inlandType: "sole",
      hopeCargoInsurance: false,
      needFTACertificateAgency: false,
      fareId: isFixedQuotation ? realtimeQuotation.data?.fare?.id : undefined,
      userNote: form.userNote,
      containLss: false,
      containOceanSurcharge: true,
      containDomesticFee: true,
      containCustoms: form.wantCustomsRequest === "yes",
      productsInfo,
      containersInfo: [],
      exporterInfo: form.exporterInfo,
      supply: rTon,
      ...(isFixedQuotation
        ? { quotationInfo: commonQuotationInfo }
        : { unFixedCostInfo: commonQuotationInfo }),
      quotationInfoForDownload: isFixedQuotation
        ? realtimeQuotation.data?.quotationInfoForDownload
        : undefined,
    };

    return { type: actionType.ORDER_SEND_REQUEST, payload };
  },
  initGetRealtimeQuotation: () => {
    return { type: actionType.INIT_ORDER_GET_REALTIME_QUOTATION };
  },
  getRealtimeQuotation: (
    form: Partial<AssistanceForm>,
    productsInfoForRequest: SendRequestPayloadProductsInfoItem[]
  ) => {
    if (!form) {
      return;
    }

    const weightAsTon = toTon(form!.weight!, "KG");
    const rTon = calculateRTon(form!.cbm!, weightAsTon);

    const payload = {
      productsInfo: productsInfoForRequest,
      startPort: form!.departurePort?.id,
      endViaPort: form!.destinationPort?.id,
      incoterms: "FOB",
      wantInsurance: false,
      containCustoms: form.wantCustomsRequest === "yes",
      rton: rTon,
      containLSS: false,
      containOceanFreightCharge: true,
      containDomesticCharge: true,
      weight: weightAsTon,
      containerCBM: form.cbm,
      zoneId: getZoneId({
        destinationType: "inland",
        destinationInlandZone: form.destinationInlandZone,
        destinationInlandMap: form.destinationInlandMap,
      })!,
      endAddress: form.destinationInlandEndAddress,
      itemNameList: [form.name],
      needFTACertificateAgency: false,
      inlandType: "sole",
    };
    return { type: actionType.ORDER_GET_REALTIME_QUOTATION, payload };
  },
  getQuotationDownloadLink: (realtimeQuotation: any) => {
    const payload = {
      id: realtimeQuotation.data.searchEstimateId,
      quotationInfoForDownload: realtimeQuotation.data.quotationInfoForDownload,
    };

    return { type: actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK, payload };
  },
  updateQuotationDownloadLinkNeedInitialDownload: (status: BooleanString) => {
    const payload = status;

    return {
      type: actionType.UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD,
      payload,
    };
  },
  initGetQuotationDownloadLink: () => {
    return { type: actionType.INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK };
  },
};

// Sagas
const sagas = {
  getRealTimeQuotationSaga: createRequestSaga(
    actionType.ORDER_GET_REALTIME_QUOTATION,
    (payload: any) => {
      return sendRequest({
        method: "get",
        path: "/searchEstimate/lcl",
        params: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  sendRequestSaga: createRequestSaga(
    actionType.ORDER_SEND_REQUEST,
    (payload: {
      form: AssistanceForm;
      realtimeQuotation: ApiResponseState<RealtimeQuotationLCLGeneral>;
      userInfo: UserInfo;
    }) => {
      return sendRequest({
        method: "post",
        path: "/bid/register",
        data: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  getQuotationDownloadLinkSaga: createRequestSaga(
    actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK,
    (payload: any) => {
      return sendRequest({
        method: "put",
        path: "/searchEstimate/download",
        data: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
};
export function* assistanceSaga() {
  yield takeLatest(
    actionType.ORDER_GET_REALTIME_QUOTATION,
    sagas.getRealTimeQuotationSaga
  );
  yield takeLatest(actionType.ORDER_SEND_REQUEST, sagas.sendRequestSaga);
  yield takeLatest(
    actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK,
    sagas.getQuotationDownloadLinkSaga
  );
}
