import * as EmailValidator from "email-validator";
import { createSelector } from "reselect";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { SendRequestPayloadProductsInfoItem } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  calculateRTon,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";

import { RootState } from "../../modules";

import { actionType } from "./actions";

const getIsStep2Validated = createSelector(
  [
    (state: RootState) => state.assistance.order.form,
    (state: RootState) => getNeedToInlandAddressDetail(state),
  ],
  (form, needToInlandAddressDetail) => {
    if (!form.departurePort) {
      return false;
    }

    if (!form.destinationPort) {
      return false;
    }

    if (form.destinationInlandType === "zone") {
      if (!form.destinationInlandZone) {
        return false;
      }
    }

    if (form.destinationInlandType === "map") {
      if (!form.destinationInlandMap) {
        return false;
      }
    }

    if (needToInlandAddressDetail && !form.destinationInlandAddressDetail) {
      return false;
    }

    return true;
  }
);

const getIsStep3Validated = createSelector(
  (state: RootState) => state.assistance.order.form,
  (form) => {
    if (!form.cbm || !Number(form.cbm)) {
      return false;
    }

    if (!form.weight || !Number(form.weight)) {
      return false;
    }

    return true;
  }
);

const getIsStep4ExportCompanyInfoValidated = createSelector(
  (state: RootState) => state.assistance.order.form,
  (form) => {
    const { exporterInfo } = form;

    if (!exporterInfo) return false;

    const { companyName, personName, personPhone, personEmail } = exporterInfo;

    if (!companyName) {
      return false;
    }

    if (!personName) {
      return false;
    }

    if (!personPhone) {
      return false;
    }

    if (!personEmail) {
      return false;
    }

    if (!EmailValidator.validate(personEmail)) {
      return false;
    }

    return true;
  }
);

/**
 * 도착지 내륙 상세 주소가 필요한지 확인
 */
const getNeedToInlandAddressDetail = createSelector(
  (state: RootState) => state.assistance.order.form,
  (form): boolean => {
    if (APP_REGION === "KR") {
      return true;
    }

    return false;
  }
);

const getProductsInfoForRequest = createSelector(
  (state: RootState) => state.assistance.order.form,
  (form): SendRequestPayloadProductsInfoItem[] => {
    const weightAsTon = toTon(form.weight!, "KG");
    const rTon = calculateRTon(form.cbm!, weightAsTon);

    const result: SendRequestPayloadProductsInfoItem[] = [
      {
        name: form.name!,
        weight: Number(weightAsTon),
        weightUnit: "TON",
        packingType: "boxes",
        isDouble: true,
        isDangerous: false,
        unitSupply: Number(rTon),
        cbm: Number(form.cbm)!,
        quantity: 1,
      },
    ];

    return result;
  }
);

const getRealtimeQuotationResult = (state: RootState) => {
  return state.assistance.order.realtimeQuotation;
};
const getQuotationDownloadLinkResult = (state: RootState) => {
  return state.assistance.order.quotationDownloadLink;
};

const getLoadingGetRealtimeQuotation = (state: RootState) => {
  return state.loading[actionType.ORDER_GET_REALTIME_QUOTATION] as boolean;
};
const getLoadingSendRequest = (state: RootState) => {
  return state.loading[actionType.ORDER_SEND_REQUEST] as boolean;
};
const getLoadingQuotationDownloadLink = (state: RootState) => {
  return state.loading[actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK] as boolean;
};
export const selectors = {
  getIsStep2Validated,
  getIsStep3Validated,
  getIsStep4ExportCompanyInfoValidated,
  getRealtimeQuotationResult,
  getQuotationDownloadLinkResult,
  getLoadingGetRealtimeQuotation,
  getLoadingSendRequest,
  getLoadingQuotationDownloadLink,
  getNeedToInlandAddressDetail,
  getProductsInfoForRequest,
};
