import produce from "immer";

import {
  ORDER_GET_INLAND_COST_RES,
  ORDER_GET_QUOTATION_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/oceanTicket";
import { APP_REGION } from "@sellernote/_shared/src/constants";
import {
  ApiResponseState,
  Country,
  Port,
} from "@sellernote/_shared/src/types/common/common";
import {
  OceanTicketDestinationPort,
  OceanTicketForm,
  OceanTicketFormCargoListItem,
  OceanTicketStateFromRealtimeService,
} from "@sellernote/_shared/src/types/forwarding/oceanTicket";
import { QuotationDownloadLink } from "@sellernote/_shared/src/types/forwarding/quotation";
import { getExpiredAt } from "@sellernote/_shared/src/utils/common/date";
import { getUpdatedObject } from "@sellernote/_shared/src/utils/common/etc";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { actionType } from "./actions";

export interface OceanTicketState {
  order: {
    step: number;
    stepOnInputValidation?: number; // input validation이 활성화된 step번호
    form: Partial<OceanTicketForm>;
    originCountries: Partial<ApiResponseState<Country[]>>;
    originPorts: Partial<ApiResponseState<Port[]>>;
    destinationPorts: Partial<ApiResponseState<OceanTicketDestinationPort[]>>;
    inlandCost: Partial<ApiResponseState<ORDER_GET_INLAND_COST_RES>>;
    quotation: Partial<ApiResponseState<ORDER_GET_QUOTATION_RES>>;
    quotationDownloadLink: Partial<QuotationDownloadLink>;
    sendRequest: Partial<ApiResponseState<any>>;
    stateFromRealtimeService?: OceanTicketStateFromRealtimeService;
  };
}

const testState = {
  order: {
    step: 3,
    form: {
      originPortRange: "country",
      cargoList: [
        {
          key: 0,
          sizeUnit: "cm",
          weightUnit: "KG",
          canStack: "yes",
          isDangerous: "no",
          name: "물품1",
          packagingType: "pallets",
          horizontal: 12,
          vertical: 32,
          height: 12,
          weight: 12,
          quantity: 21,
        },
        {
          key: 1,
          sizeUnit: "cm",
          weightUnit: "KG",
          canStack: "yes",
          isDangerous: "no",
          name: "물품2",
          packagingType: "boxes",
          horizontal: 23,
          vertical: 12,
          height: 12,
          weight: 8,
          quantity: 12,
        },
      ],
      cargoType: "each",
      destinationInlandType: "zone",
      originCountry: {
        id: 2,
        continentId: 1,
        region: "Asia",
        name: "중국",
        nameKR: "중국",
        nameEN: "China",
        order: 1,
        isUse: true,
        isOceanTicket: true,
        code: "CN",
        oceanTicketPortQuantity: 15,
      },
      originType: "port",
      originPort: {
        id: 123,
        code: "CNSTG",
        codeMT: "CNSWA",
        name: "산토우",
        nameC: null,
        nameEN: "Shantou port",
        country: "CN",
        region: "Asia",
        type: "sea",
        order: 1,
        isDefault: false,
        lat: 23.341,
        lng: 116.6715,
        isOceanTicketOriginPort: true,
        isActiveOceanTicketPort: true,
        countryName: "중국",
      },
      destinationType: "port",
      destinationPort: {
        id: 9,
        code: "KRINC",
        country: "KR",
        lat: 37.46552,
        lng: 126.612843,
        name: "인천항",
        nameC: "인천구항",
        nameEN: "Incheon port",
        type: "sea",
        isActive: true,
        frequency: 3,
        leadTime: 4,
        startPort: {
          id: 123,
          code: "CNSTG",
          country: "CN",
          lat: 23.341,
          lng: 116.6715,
          name: "산토우",
          nameC: null,
          nameEN: "Shantou port",
          type: "sea",
        },
      },
      cargoWantInsurance: "yes",
    },
    originCountries: {
      data: [
        {
          id: 2,
          continentId: 1,
          region: "Asia",
          name: "중국",
          nameKR: "중국",
          nameEN: "China",
          order: 1,
          isUse: true,
          isOceanTicket: true,
          code: "CN",
        },
        {
          id: 9,
          continentId: 1,
          region: "Asia",
          name: "홍콩",
          nameKR: "홍콩",
          nameEN: "Hong Kong",
          order: 3,
          isUse: true,
          isOceanTicket: true,
          code: "HK",
        },
        {
          id: 10,
          continentId: 1,
          region: "Asia",
          name: "인도네시아",
          nameKR: "인도네시아",
          nameEN: "Indonesia",
          order: 10,
          isUse: true,
          isOceanTicket: true,
          code: "ID",
        },
        {
          id: 11,
          continentId: 1,
          region: "Asia",
          name: "인도",
          nameKR: "인도",
          nameEN: "India",
          order: 11,
          isUse: true,
          isOceanTicket: true,
          code: "IN",
        },
        {
          id: 12,
          continentId: 1,
          region: "Asia",
          name: "일본",
          nameKR: "일본",
          nameEN: "Japan",
          order: 4,
          isUse: true,
          isOceanTicket: true,
          code: "JP",
        },
        {
          id: 22,
          continentId: 1,
          region: "Asia",
          name: "말레이시아",
          nameKR: "말레이시아",
          nameEN: "Malaysia",
          order: 9,
          isUse: true,
          isOceanTicket: true,
          code: "MY",
        },
        {
          id: 24,
          continentId: 1,
          region: "Asia",
          name: "필리핀",
          nameKR: "필리핀",
          nameEN: "Philippines",
          order: 8,
          isUse: true,
          isOceanTicket: true,
          code: "PH",
        },
        {
          id: 26,
          continentId: 1,
          region: "Asia",
          name: "싱가포르",
          nameKR: "싱가포르",
          nameEN: "Singapore",
          order: 29,
          isUse: true,
          isOceanTicket: true,
          code: "SG",
        },
        {
          id: 27,
          continentId: 1,
          region: "Asia",
          name: "태국",
          nameKR: "태국",
          nameEN: "Thailand",
          order: 5,
          isUse: true,
          isOceanTicket: true,
          code: "TH",
        },
        {
          id: 32,
          continentId: 1,
          region: "Asia",
          name: "대만",
          nameKR: "대만",
          nameEN: "Taiwan",
          order: 6,
          isUse: true,
          isOceanTicket: true,
          code: "TW",
        },
        {
          id: 34,
          continentId: 1,
          region: "Asia",
          name: "베트남",
          nameKR: "베트남",
          nameEN: "Vietnam",
          order: 7,
          isUse: true,
          isOceanTicket: true,
          code: "VN",
        },
        {
          id: 62,
          continentId: 3,
          region: "Europe",
          name: "벨기에",
          nameKR: "벨기에",
          nameEN: "Belgium",
          order: 62,
          isUse: true,
          isOceanTicket: true,
          code: "BE",
        },
        {
          id: 69,
          continentId: 3,
          region: "Europe",
          name: "독일",
          nameKR: "독일",
          nameEN: "Germany",
          order: 58,
          isUse: true,
          isOceanTicket: true,
          code: "DE",
        },
        {
          id: 72,
          continentId: 3,
          region: "Europe",
          name: "스페인",
          nameKR: "스페인",
          nameEN: "Spain",
          order: 72,
          isUse: true,
          isOceanTicket: true,
          code: "ES",
        },
        {
          id: 73,
          continentId: 3,
          region: "Europe",
          name: "핀란드",
          nameKR: "핀란드",
          nameEN: "Finland",
          order: 73,
          isUse: true,
          isOceanTicket: true,
          code: "FI",
        },
        {
          id: 75,
          continentId: 3,
          region: "Europe",
          name: "프랑스",
          nameKR: "프랑스",
          nameEN: "France",
          order: 59,
          isUse: true,
          isOceanTicket: true,
          code: "FR",
        },
        {
          id: 77,
          continentId: 3,
          region: "Europe",
          name: "영국",
          nameKR: "영국",
          nameEN: "United Kingdom",
          order: 77,
          isUse: true,
          isOceanTicket: true,
          code: "GB",
        },
        {
          id: 88,
          continentId: 3,
          region: "Europe",
          name: "이탈리아",
          nameKR: "이탈리아",
          nameEN: "Italy",
          order: 88,
          isUse: true,
          isOceanTicket: true,
          code: "IT",
        },
        {
          id: 100,
          continentId: 3,
          region: "Europe",
          name: "네덜란드",
          nameKR: "네덜란드",
          nameEN: "Netherlands",
          order: 100,
          isUse: true,
          isOceanTicket: true,
          code: "NL",
        },
        {
          id: 101,
          continentId: 3,
          region: "Europe",
          name: "노르웨이",
          nameKR: "노르웨이",
          nameEN: "Norway",
          order: 101,
          isUse: true,
          isOceanTicket: true,
          code: "NO",
        },
        {
          id: 107,
          continentId: 3,
          region: "Europe",
          name: "스웨덴",
          nameKR: "스웨덴",
          nameEN: "Sweden",
          order: 107,
          isUse: true,
          isOceanTicket: true,
          code: "SE",
        },
        {
          id: 119,
          continentId: 4,
          region: "North America",
          name: "미국",
          nameKR: "미국",
          nameEN: "United States of America",
          order: 119,
          isUse: true,
          isOceanTicket: true,
          code: "US",
        },
        {
          id: 130,
          continentId: 5,
          region: "Latin America",
          name: "브라질",
          nameKR: "브라질",
          nameEN: "Brazil",
          order: 130,
          isUse: true,
          isOceanTicket: true,
          code: "BR",
        },
        {
          id: 133,
          continentId: 5,
          region: "Latin America",
          name: "칠레",
          nameKR: "칠레",
          nameEN: "Chile",
          order: 133,
          isUse: true,
          isOceanTicket: true,
          code: "CL",
        },
        {
          id: 157,
          continentId: 5,
          region: "Latin America",
          name: "멕시코",
          nameKR: "멕시코",
          nameEN: "Mexico",
          order: 157,
          isUse: true,
          isOceanTicket: true,
          code: "MX",
        },
      ],
      status: "SUCCESS",
    },
    originPorts: {
      data: [
        {
          id: 30,
          code: "CNYAT",
          codeMT: null,
          name: "연태항",
          nameC: null,
          nameEN: "Yantai port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 16,
          isDefault: true,
          lat: 37.582582,
          lng: 121.375069,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 33,
          code: "CNDAL",
          codeMT: null,
          name: "대련항",
          nameC: null,
          nameEN: "Dalian port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 6,
          isDefault: true,
          lat: 38.930909,
          lng: 121.662576,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 34,
          code: "CNFZH",
          codeMT: null,
          name: "푸조우항",
          nameC: null,
          nameEN: "Fuzhou port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 10,
          isDefault: true,
          lat: 26.047286,
          lng: 119.554301,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 35,
          code: "CNNBO",
          codeMT: null,
          name: "닝보항",
          nameC: null,
          nameEN: "Ningbo port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 29.9402558772419,
          lng: 121.83946255896242,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 36,
          code: "CNSHK",
          codeMT: null,
          name: "셔코우항",
          nameC: "세코우항",
          nameEN: "Shekou port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 11,
          isDefault: true,
          lat: 22.477341,
          lng: 113.913284,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 37,
          code: "CNWEI",
          codeMT: null,
          name: "위해항",
          nameC: "웨이하이항",
          nameEN: "Weihai port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 7,
          isDefault: true,
          lat: 37.443298,
          lng: 122.201379,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 38,
          code: "CNXAM",
          codeMT: null,
          name: "샤먼항",
          nameC: "셔먼항",
          nameEN: "Xiamen port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 3,
          isDefault: true,
          lat: 24.421356,
          lng: 118.068449,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 39,
          code: "CNQIN",
          codeMT: null,
          name: "청도항",
          nameC: "칭다오항",
          nameEN: "Qingdao port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 8,
          isDefault: true,
          lat: 36.023956,
          lng: 120.205123,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 40,
          code: "CNTNJ",
          codeMT: null,
          name: "천진항",
          nameC: "텐진항",
          nameEN: "Tianjin port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 4,
          isDefault: true,
          lat: 38.987214,
          lng: 117.742362,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 41,
          code: "CNXGA",
          codeMT: null,
          name: "신강항",
          nameC: "싱강항",
          nameEN: "Xingang port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 9,
          isDefault: true,
          lat: 38.987214,
          lng: 117.742362,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 43,
          code: "CNSGH",
          codeMT: null,
          name: "상해항",
          nameC: "상하이항",
          nameEN: "Shanghai port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 31.339001,
          lng: 121.660112,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 44,
          code: "CNSNZ",
          codeMT: null,
          name: "선전항",
          nameC: "심천항",
          nameEN: "Shenzhen port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 15,
          isDefault: true,
          lat: 22.512677,
          lng: 113.87391,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 45,
          code: "CNGGZ",
          codeMT: null,
          name: "광저우항",
          nameC: null,
          nameEN: "Guangzhou port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 13,
          isDefault: true,
          lat: 22.758071,
          lng: 113.568269,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 108,
          code: "CNHUA",
          codeMT: null,
          name: "황푸항",
          nameC: null,
          nameEN: "Huangpu port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 19,
          isDefault: true,
          lat: 22.758071,
          lng: 113.568269,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 123,
          code: "CNSTG",
          codeMT: "CNSWA",
          name: "산토우",
          nameC: null,
          nameEN: "Shantou port",
          country: "CN",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: false,
          lat: 23.341,
          lng: 116.6715,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 208,
          code: "HKHKG",
          codeMT: null,
          name: "홍콩항",
          nameC: null,
          nameEN: "Hong Kong port",
          country: "HK",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 22.287945,
          lng: 114.18135,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 338,
          code: "JPUKB",
          codeMT: null,
          name: "고베항",
          nameC: "코베항",
          nameEN: "kobe port",
          country: "JP",
          region: "Asia",
          type: "sea",
          order: 6,
          isDefault: true,
          lat: 34.67833,
          lng: 135.231498,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 347,
          code: "JPOSA",
          codeMT: null,
          name: "오사카항",
          nameC: null,
          nameEN: "Osaka port port",
          country: "JP",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 34.648302,
          lng: 135.409317,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 350,
          code: "JPTYO",
          codeMT: null,
          name: "도쿄항",
          nameC: "토쿄항",
          nameEN: "Tokyo port",
          country: "JP",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 35.623228,
          lng: 139.808944,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 353,
          code: "JPNGO",
          codeMT: null,
          name: "나고야항",
          nameC: null,
          nameEN: "Nagoya port",
          country: "JP",
          region: "Asia",
          type: "sea",
          order: 4,
          isDefault: true,
          lat: 35.080807,
          lng: 136.877039,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 379,
          code: "JPYOK",
          codeMT: null,
          name: "요코하마항",
          nameC: null,
          nameEN: "Yokohama port",
          country: "JP",
          region: "Asia",
          type: "sea",
          order: 3,
          isDefault: true,
          lat: 35.452254,
          lng: 139.662456,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 456,
          code: "TWKEL",
          codeMT: null,
          name: "기륭항",
          nameC: "지룽항",
          nameEN: "Keelung port",
          country: "TW",
          region: "Asia",
          type: "sea",
          order: 3,
          isDefault: true,
          lat: 25.137611,
          lng: 121.743068,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 458,
          code: "TWTXG",
          codeMT: null,
          name: "타이충항",
          nameC: null,
          nameEN: "Taichung port",
          country: "TW",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 24.282585,
          lng: 120.514398,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 459,
          code: "TWKHH",
          codeMT: null,
          name: "카오슝항",
          nameC: "가오슝항",
          nameEN: "Kaohsiung port",
          country: "TW",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 22.609777,
          lng: 120.283156,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 471,
          code: "VNHPH",
          codeMT: null,
          name: "하이퐁항",
          nameC: null,
          nameEN: "HaiPhong port",
          country: "VN",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 20.829024,
          lng: 106.786943,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 474,
          code: "VNSGN",
          codeMT: null,
          name: "호치민항",
          nameC: null,
          nameEN: "HoChiMinh port",
          country: "VN",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 10.759149,
          lng: 106.728761,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 489,
          code: "SGSIN",
          codeMT: null,
          name: "싱가폴항",
          nameC: "싱가포르항",
          nameEN: "Singapore port",
          country: "SG",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 1.277193,
          lng: 103.772046,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 500,
          code: "IDJKT",
          codeMT: null,
          name: "자카르타항",
          nameC: null,
          nameEN: "Jakarta port",
          country: "ID",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: -6.091637308743987,
          lng: 106.88170050502092,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 525,
          code: "PHMNL",
          codeMT: null,
          name: "마닐라항",
          nameC: null,
          nameEN: "Manila port",
          country: "PH",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 14.61449,
          lng: 120.95763,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 538,
          code: "MYPEN",
          codeMT: null,
          name: "페낭항",
          nameC: null,
          nameEN: "Penang port",
          country: "MY",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 5.421865023987897,
          lng: 100.36523371639998,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 543,
          code: "MYPKG",
          codeMT: null,
          name: "포트클랑항",
          nameC: "포트켈랑항",
          nameEN: "PortKlang port",
          country: "MY",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 2.999497046214592,
          lng: 101.39325990640056,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 545,
          code: "MYPGU",
          codeMT: null,
          name: "파시르구당항",
          nameC: null,
          nameEN: "PasirGudang port",
          country: "MY",
          region: "Asia",
          type: "sea",
          order: 4,
          isDefault: true,
          lat: 1.445970354331277,
          lng: 103.90617000472454,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 556,
          code: "THBKK",
          codeMT: null,
          name: "방콕항",
          nameC: null,
          nameEN: "Bangkok port",
          country: "TH",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 13.7035208,
          lng: 100.5669906,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 777,
          code: "USCHI",
          codeMT: null,
          name: "시카고 철도역(LA항 경유)",
          nameC: null,
          nameEN: "Chicago,IL Rail ramp(via LA port)",
          country: "US",
          region: "North America",
          type: "sea",
          order: 1,
          isDefault: false,
          lat: 41.8855,
          lng: -87.607,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 792,
          code: "USNYC",
          codeMT: null,
          name: "뉴욕항",
          nameC: null,
          nameEN: "NewYork port",
          country: "US",
          region: "North America",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 40.6759,
          lng: -74.0829,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 841,
          code: "USLAX",
          codeMT: null,
          name: "로스엔젤레스항",
          nameC: "엘에이항",
          nameEN: "LosAngeles port",
          country: "US",
          region: "North America",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 33.74021,
          lng: -118.265,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 915,
          code: "BRSSZ",
          codeMT: null,
          name: "산토스항",
          nameC: null,
          nameEN: "Santos port",
          country: "BR",
          region: "Latin America",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: -23.95109,
          lng: -46.35358,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 933,
          code: "CLVAP",
          codeMT: null,
          name: "발파라이소항",
          nameC: null,
          nameEN: "Valparaiso port",
          country: "CL",
          region: "Latin America",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: -33.03416,
          lng: -71.613695,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 965,
          code: "MXZLO",
          codeMT: null,
          name: "만자니로항",
          nameC: "만자니요항",
          nameEN: "Manzanillo port",
          country: "MX",
          region: "Latin America",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 19.07729,
          lng: -104.3225,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1604,
          code: "BEANR",
          codeMT: null,
          name: "앤트워프항",
          nameC: "안트워프항",
          nameEN: "Antwerp port",
          country: "BE",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 51.30249,
          lng: 4.3114605,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1613,
          code: "DEHAM",
          codeMT: null,
          name: "함부르크항",
          nameC: null,
          nameEN: "Hamburg port",
          country: "DE",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 53.507,
          lng: 9.9619,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1639,
          code: "ESBCN",
          codeMT: null,
          name: "바르셀로나항",
          nameC: null,
          nameEN: "Barcelona port",
          country: "ES",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 41.352375,
          lng: 2.158441,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1653,
          code: "FIHEL",
          codeMT: null,
          name: "헬싱키항",
          nameC: null,
          nameEN: "Helsinki port",
          country: "FI",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 60.1685,
          lng: 24.9445,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1667,
          code: "FRLEH",
          codeMT: "FRLEH",
          name: "르하브르항",
          nameC: "르아브르항",
          nameEN: "LeHavre port",
          country: "FR",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 49.472655,
          lng: 0.14620419,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1688,
          code: "GBSOU",
          codeMT: null,
          name: "사우샘프턴항",
          nameC: "사우스햄튼항",
          nameEN: "Southampton port",
          country: "GB",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 50.898175,
          lng: -1.4205025,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1706,
          code: "ITGOA",
          codeMT: "ITGOA",
          name: "제노아항",
          nameC: "제노바항",
          nameEN: "Genoa port",
          country: "IT",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 44.403545,
          lng: 8.909109,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1726,
          code: "NLRTM",
          codeMT: null,
          name: "로테르담항",
          nameC: null,
          nameEN: "Rotterdam port",
          country: "NL",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 51.943305,
          lng: 4.141812,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1730,
          code: "NOOSL",
          codeMT: null,
          name: "오슬로항",
          nameC: null,
          nameEN: "Oslo port",
          country: "NO",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 59.89892,
          lng: 10.725,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1796,
          code: "SEGOT",
          codeMT: null,
          name: "괴텐버그항",
          nameC: null,
          nameEN: "Gothenburg",
          country: "SE",
          region: "Europe",
          type: "sea",
          order: 1,
          isDefault: false,
          lat: 57.6995,
          lng: 11.883,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1903,
          code: "INNSA",
          codeMT: null,
          name: "나바샤바항",
          nameC: "나바사바항",
          nameEN: "NhavaSheva port",
          country: "IN",
          region: "Asia",
          type: "sea",
          order: 2,
          isDefault: true,
          lat: 18.950614644665283,
          lng: 72.95131883267624,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
        {
          id: 1906,
          code: "INMAA",
          codeMT: null,
          name: "첸나이항",
          nameC: null,
          nameEN: "Chennai port",
          country: "IN",
          region: "Asia",
          type: "sea",
          order: 1,
          isDefault: true,
          lat: 13.083303402480295,
          lng: 80.29536614857317,
          isOceanTicketOriginPort: true,
          isActiveOceanTicketPort: true,
        },
      ],
      status: "SUCCESS",
    },
    destinationPorts: {
      data: [
        {
          id: 9,
          code: "KRINC",
          country: "KR",
          lat: 37.46552,
          lng: 126.612843,
          name: "인천항",
          nameC: "인천구항",
          nameEN: "Incheon port",
          type: "sea",
          isActive: true,
          frequency: 3,
          leadTime: 4,
          startPort: {
            id: 123,
            code: "CNSTG",
            country: "CN",
            lat: 23.341,
            lng: 116.6715,
            name: "산토우",
            nameC: null,
            nameEN: "Shantou port",
            type: "sea",
          },
        },
        {
          id: 1,
          code: "KRPUS",
          country: "KR",
          lat: 35.108155,
          lng: 129.053807,
          name: "부산항",
          nameC: "부산구항",
          nameEN: "Busan port",
          type: "sea",
          isActive: true,
          frequency: 3,
          leadTime: 4,
          startPort: {
            id: 123,
            code: "CNSTG",
            country: "CN",
            lat: 23.341,
            lng: 116.6715,
            name: "산토우",
            nameC: null,
            nameEN: "Shantou port",
            type: "sea",
          },
        },
      ],
      status: "SUCCESS",
    },
    inlandCost: {},
    quotation: {},
    quotationDownloadLink: {},
    shipSchedule: {},
    sendRequest: {},
    stepOnInputValidation: 3,
  },
} as unknown as OceanTicketState;

export const cargoListEachDefaultState: Partial<OceanTicketFormCargoListItem> =
  {
    sizeUnit: "cm",
    weightUnit: "KG",
    canStack: "yes",
    isDangerous: "no",
  };
export const cargoListEachInitialState: Partial<OceanTicketFormCargoListItem>[] =
  [
    {
      key: 0,
      ...cargoListEachDefaultState,
    },
  ];
export const cargoListTotalInitialState: Partial<OceanTicketFormCargoListItem>[] =
  [
    {
      key: 0,
      weightUnit: "KG",
      canStack: "yes",
      isDangerous: "no",
    },
  ];

// Reducer
const initialState: OceanTicketState = {
  order: {
    step: 1,
    form: {
      originPortRange: "country",
      cargoList: cargoListEachInitialState,
      cargoType: "each",
      destinationInlandType: APP_REGION === "KR" ? "zone" : "map",
    },
    originCountries: apiResponseInitialState,
    originPorts: apiResponseInitialState,
    destinationPorts: apiResponseInitialState,
    inlandCost: apiResponseInitialState,
    quotation: apiResponseInitialState,
    quotationDownloadLink: apiResponseInitialState,
    sendRequest: apiResponseInitialState,
    stateFromRealtimeService: undefined,
  },
};

export const reducer = (state = initialState, action: any) => {
  return produce(state, (draft: OceanTicketState) => {
    switch (action.type) {
      case actionType.INIT_ORDER: {
        draft.order = initialState.order;

        break;
      }
      case actionType.INIT_ORDER_FROM_REALTIME_SERVICE: {
        if (state.order.stateFromRealtimeService) {
          draft.order.step = state.order.stateFromRealtimeService.step;
          draft.order.form = state.order.stateFromRealtimeService.form;
          draft.order.stateFromRealtimeService = undefined;
        }

        break;
      }

      case actionType.ORDER_UPDATE_STEP: {
        draft.order.step = action.payload;
        break;
      }

      case actionType.ORDER_UPDATE_STEP_ON_INPUT_VALIDATION: {
        draft.order.stepOnInputValidation = action.payload;
        break;
      }

      case actionType.ORDER_UPDATE_FORM_ITEM: {
        draft.order.form = getUpdatedObject(
          draft.order.form!,
          action.payload.key,
          action.payload.value
        );

        // originCountry이 바뀌면 의존 state를 초기화
        if (
          draft.order.form?.originCountry?.id !==
          state.order.form?.originCountry?.id
        ) {
          draft.order.form!.originType = undefined;

          if (state.order.form?.destinationType) {
            draft.order.form!.destinationType === undefined;
          }
        }

        // originType 바뀌면 의존 state를 초기화
        if (draft.order.form?.originType !== state.order.form?.originType) {
          draft.order.form!.originPort = undefined;
          draft.order.form!.originInlandAddress = undefined;
          draft.order.form!.originPortRange = "country";
        }

        // originPortRange 바뀌면 의존 state를 초기화
        if (
          draft.order.form?.originPortRange !==
          state.order.form?.originPortRange
        ) {
          draft.order.form!.originPort = undefined;
          draft.order.form!.originInlandAddress = undefined;
        }

        // destinationType 바뀌면 의존 state를 초기화
        if (
          draft.order.form?.destinationType !==
          state.order.form?.destinationType
        ) {
          draft.order.form!.destinationPort = undefined;
          draft.order.form!.destinationInlandZone = undefined;
          draft.order.form!.destinationInlandMap = undefined;
        }

        // cargoType이 바뀌면 cargoList를 초기화함
        if (draft.order.form?.cargoType !== state.order.form?.cargoType) {
          draft.order.form!.cargoList =
            draft.order.form?.cargoType === "total"
              ? cargoListTotalInitialState
              : cargoListEachInitialState;
        }
        break;
      }

      case actionType.INIT_ORDER_GET_ORIGIN_COUNTRIES: {
        draft.order.originCountries = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_GET_ORIGIN_COUNTRIES_SUCCESS: {
        draft.order.originCountries = {
          data: action.payload,
          status: "SUCCESS",
        };

        break;
      }
      case actionType.ORDER_GET_ORIGIN_COUNTRIES_FAILURE: {
        draft.order.originCountries = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_ORIGIN_PORTS: {
        draft.order.originPorts = apiResponseInitialState;
        break;
      }
      case actionType.ORDER_GET_ORIGIN_PORTS_SUCCESS: {
        draft.order.originPorts = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }
      case actionType.ORDER_GET_ORIGIN_PORTS_FAILURE: {
        draft.order.originPorts = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_DESTINATION_PORTS: {
        draft.order.destinationPorts = apiResponseInitialState;
        break;
      }
      case actionType.ORDER_GET_DESTINATION_PORTS_SUCCESS: {
        let result: OceanTicketDestinationPort[];

        if (action.payload) {
          result = action.payload.map((v: any) => {
            return {
              ...v.endPort,
              isActive: v.isActive,
              frequency: v.frequency,
              leadTime: v.leadTime,
              startPort: v.startPort,
            } as OceanTicketDestinationPort;
          });
        } else {
          result = [];
        }

        draft.order.destinationPorts = {
          data: result,
          status: "SUCCESS",
        };

        break;
      }
      case actionType.ORDER_GET_DESTINATION_PORTS_FAILURE: {
        draft.order.destinationPorts = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_INLAND_COST: {
        draft.order.inlandCost = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_GET_INLAND_COST_SUCCESS: {
        draft.order.inlandCost = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }

      case actionType.ORDER_GET_INLAND_COST_FAILURE: {
        draft.order.inlandCost = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.ORDER_GET_QUOTATION_SUCCESS: {
        draft.order.quotation = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }

      case actionType.ORDER_GET_QUOTATION_FAILURE: {
        draft.order.quotation = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_QUOTATION: {
        draft.order.quotation = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS: {
        const duration =
          action.payload.preSignedUrl &&
          action.payload.preSignedUrl.expirationTime;

        draft.order.quotationDownloadLink = {
          data: action.payload,
          status: "SUCCESS",
          needInitialDownload: "yes",
          expiredAt: getExpiredAt(duration - 60), // buffer로 1분을 추가
        };
        break;
      }

      case actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE: {
        draft.order.quotationDownloadLink = {
          data: null,
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK: {
        draft.order.quotationDownloadLink =
          initialState.order.quotationDownloadLink;
        break;
      }

      case actionType.UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD: {
        draft.order.quotationDownloadLink!.needInitialDownload = action.payload;
        break;
      }

      case actionType.ORDER_SEND_REQUEST_SUCCESS: {
        draft.order.sendRequest = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }

      case actionType.ORDER_SEND_REQUEST_FAILURE: {
        draft.order.sendRequest = {
          data: null,
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_ORDER_SEND_REQUEST: {
        draft.order.sendRequest = apiResponseInitialState;
        break;
      }

      case actionType.ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE: {
        draft.order.stateFromRealtimeService = action.payload;
        break;
      }

      case actionType.INIT_ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE: {
        draft.order.stateFromRealtimeService = undefined;
        break;
      }
    }
  });
};
