import React from "react";
import { createSelector } from "reselect";

import {
  APP_CURRENT_LANGUAGE,
  APP_REGION,
} from "@sellernote/_shared/src/constants";
import { InputValidationResult } from "@sellernote/_shared/src/types/common/common";
import {
  getOrdinalString,
  toFixedFloat,
} from "@sellernote/_shared/src/utils/common/number";
import {
  calculateCBM,
  calculateRTon,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";

import { RootState } from "../../modules";
import { isAvailableFeature, Trans } from "../../utils/i18n";

import { actionType } from "./actions";

const getIsReadyToCalculateCargoSum = createSelector(
  (state: RootState) => state.oceanTicket.order.form.cargoList,
  (cargoList) => {
    if (!(cargoList && cargoList.length > 0)) {
      return false;
    }

    return cargoList.every((v) => {
      if (v.cbm && v.weight && v.weightUnit) {
        return true;
      }

      if (
        v.horizontal &&
        v.height &&
        v.vertical &&
        v.sizeUnit &&
        v.weight &&
        v.weightUnit &&
        v.quantity
      ) {
        return true;
      }

      return false;
    });
  }
);

const getTotalCbm = createSelector(
  [
    getIsReadyToCalculateCargoSum,
    (state: RootState) => state.oceanTicket.order.form.cargoList,
  ],
  (isReadyForCalculation, cargoList) => {
    if (!isReadyForCalculation) {
      return 0;
    }
    let sum = 0;
    if (!(cargoList && cargoList.length > 0)) {
      return sum;
    }

    cargoList.forEach((v) => {
      let subSum = 0;
      if (v.cbm) {
        subSum += v.cbm;
      } else {
        subSum =
          calculateCBM({
            type: "lcl",
            width: v.horizontal || 0,
            height: v.height || 0,
            depth: v.vertical || 0,
            sizeUnit: v.sizeUnit,
          })! * (v.quantity || 0);
      }

      sum += subSum;
    });

    return toFixedFloat(sum, 2) || 0;
  }
);

const getTotalWeightAsTon = createSelector(
  [
    getIsReadyToCalculateCargoSum,
    (state: RootState) => state.oceanTicket.order.form.cargoList,
  ],
  (isReadyForCalculation, cargoList) => {
    if (!isReadyForCalculation) {
      return 0;
    }

    let sum = 0;
    if (!(cargoList && cargoList.length > 0)) {
      return sum;
    }

    cargoList.forEach((v) => {
      const weightAsTon = toTon(v.weight!, v.weightUnit!);
      const subSum = v.quantity ? weightAsTon * (v.quantity || 1) : weightAsTon;
      if (subSum) {
        sum += subSum;
      }
    });

    return toFixedFloat(sum, 2) || 0;
  }
);

const getTotalRton = createSelector(
  [getTotalCbm, getTotalWeightAsTon],
  (totalCbm, totalWeightAsTon) => {
    return calculateRTon(totalCbm, totalWeightAsTon);
  }
);

const getLoadingQuotationDownloadLink = (state: RootState) => {
  return state.loading[actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK] as boolean;
};

const getLoadingSendRequest = (state: RootState) => {
  return state.loading[actionType.ORDER_SEND_REQUEST] as boolean;
};

const getIsOriginInlandAddressInputted = createSelector(
  (state: RootState) => state.oceanTicket.order.form,
  (form) => {
    return (
      Boolean(form.originInlandAddress) && form.originInlandAddress!.length > 0
    );
  }
);

const getStep1InputValidation = createSelector(
  [
    (state: RootState) => state.oceanTicket.order.form,
    getIsOriginInlandAddressInputted,
  ],
  (form, isOriginInlandAddressInputted): InputValidationResult => {
    if (!form.originCountry) {
      return {
        status: "invalid",
        message: "출발지 국가를 선택하세요",
      };
    }

    if (!form.originType) {
      return {
        status: "invalid",
        message: "출발지 유형을 선택하세요",
      };
    }

    if (form.originType === "port") {
      if (!form.originPort) {
        return {
          status: "invalid",
          message: "출발지 항구를 선택하세요",
        };
      }
    } else {
      if (!form.originPort) {
        return {
          status: "invalid",
          message: "경유지 항구를 선택하세요",
        };
      }

      if (!isOriginInlandAddressInputted) {
        return {
          status: "invalid",
          message: "출발지 내륙주소를 입력하세요",
        };
      }
    }

    return { status: "valid" };
  }
);

const getStep2InputValidation = createSelector(
  [
    (state: RootState) => state.oceanTicket.order.form,
    (state: RootState) => getNeedToInlandAddressDetail(state),
  ],
  (form, needToInlandAddressDetail): InputValidationResult => {
    if (!form.destinationType) {
      return {
        status: "invalid",
        message: "도착지 유형을 선택하세요",
      };
    }

    if (form.destinationType === "port") {
      if (!form.destinationPort) {
        return {
          status: "invalid",
          message: "도착지 항구를 선택하세요",
        };
      }
    } else {
      if (!form.destinationInlandZone && !form.destinationInlandMap) {
        return {
          status: "invalid",
          message: "최종 도착지를 선택하세요",
        };
      }

      if (needToInlandAddressDetail && !form.destinationInlandAddressDetail) {
        return {
          status: "invalid",
          message: "최종 도착지 상세주소를 입력하세요",
        };
      }

      if (!form.destinationPort) {
        return {
          status: "invalid",
          message: "경유지 항구를 선택하세요",
        };
      }
    }

    return { status: "valid" };
  }
);

const getStep3InputValidation = createSelector(
  // TODO: 실제로 유저에게 validation modal로 뜰 수 있는 부분 만 번역해 둠

  (state: RootState) => state.oceanTicket.order.form,
  (form): InputValidationResult => {
    if (!form.cargoType) {
      return {
        status: "invalid",
        message: "화물정보 입력 방법을 선택하세요",
      };
    }

    if (!form.cargoList) {
      return {
        status: "invalid",
        message: "화물정보를 입력하세요",
      };
    }

    let finalInvalidCargoMessage = "";
    const hasInvalidCargo = form.cargoList.some((cargo, cargoIndex) => {
      const cargoOrdinalString =
        form.cargoType === "each" ? `${getOrdinalString(cargoIndex + 1)} ` : "";

      const invalidCargoMessage =
        APP_CURRENT_LANGUAGE === "ko"
          ? `${cargoOrdinalString}화물정보를 모두 입력해주세요`
          : `Please enter all the information of ${cargoOrdinalString}cargo.`;

      if (
        !(
          cargo.name &&
          cargo.packagingType &&
          cargo.weight &&
          cargo.weightUnit &&
          cargo.isDangerous &&
          cargo.canStack
        )
      ) {
        finalInvalidCargoMessage = invalidCargoMessage;
        return true;
      }

      if (form.cargoType === "total") {
        if (!cargo.cbm) {
          finalInvalidCargoMessage = invalidCargoMessage;
          return true;
        }
      } else {
        if (
          !(
            cargo.horizontal &&
            cargo.vertical &&
            cargo.height &&
            cargo.sizeUnit &&
            cargo.quantity
          )
        ) {
          finalInvalidCargoMessage = invalidCargoMessage;
          return true;
        }
      }
    });
    if (hasInvalidCargo) {
      return {
        status: "invalid",
        message: finalInvalidCargoMessage,
      };
    }

    if (
      isAvailableFeature("oceanTicketInlandCostOption") &&
      form.destinationType === "inland"
    ) {
      if (!form.inlandTransportType) {
        return {
          status: "invalid",
          message: <Trans i18nKey="validation:error.needInlandTransportType" />,
        };
      }
    }

    return { status: "valid" };
  }
);

/**
 * 오션티켓의 경우 인코텀즈를 유저가 선택하지 않고, 조건에 따라 인코텀즈가 자동으로 산정된다.
 */
const getIncoterms = createSelector(
  (state: RootState) => state.oceanTicket.order.form,
  (form) => {
    switch (form.originType) {
      case "port": {
        return "FOB";
      }
      case "inland": {
        return "EXW";
      }
      default: {
        return "";
      }
    }
  }
);

const getOriginCountries = createSelector(
  (state: RootState) => state.oceanTicket.order.originCountries,
  (countries) => countries?.data || []
);

/**
 * 도착지 내륙 상세 주소가 필요한지 확인
 */
const getNeedToInlandAddressDetail = createSelector(
  (state: RootState) => state.oceanTicket.order.form,
  (form): boolean => {
    if (APP_REGION !== "KR") {
      return false;
    }

    if (form.destinationType === "inland") {
      return true;
    }

    return false;
  }
);

// Selectors
export const selectors = {
  getTotalRton,
  getTotalWeightAsTon,
  getTotalCbm,
  getLoadingQuotationDownloadLink,
  getLoadingSendRequest,
  getIsOriginInlandAddressInputted,
  getStep1InputValidation,
  getStep2InputValidation,
  getStep3InputValidation,
  getIncoterms,
  getOriginCountries,
  getNeedToInlandAddressDetail,
};
