import { takeLatest } from "redux-saga/effects";

import {
  OceanTicketSendRequestPayload,
  ORDER_GET_QUOTATION_REQ,
  ORDER_GET_QUOTATION_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/oceanTicket";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { BooleanString } from "@sellernote/_shared/src/types/common/common";
import {
  CargoTransportType,
  SendRequestPayloadProductsInfoItem,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { OceanTicketStateFromRealtimeService } from "@sellernote/_shared/src/types/forwarding/oceanTicket";

import { createRequestSaga } from "../../services/legacyRequest";

// Action Type
const prefix = "oceanTicket/";
export const actionType = {
  INIT_ORDER: `${prefix}INIT_ORDER`,

  INIT_ORDER_FROM_REALTIME_SERVICE: `${prefix}INIT_ORDER_FROM_REALTIME_SERVICE`,

  ORDER_UPDATE_FORM_ITEM: `${prefix}ORDER_UPDATE_FORM_ITEM`,
  ORDER_UPDATE_STEP: `${prefix}ORDER_UPDATE_STEP`,
  ORDER_UPDATE_STEP_ON_INPUT_VALIDATION: `${prefix}ORDER_UPDATE_STEP_ON_INPUT_VALIDATION`,

  ORDER_GET_ORIGIN_COUNTRIES: `${prefix}ORDER_GET_ORIGIN_COUNTRIES`,
  ORDER_GET_ORIGIN_COUNTRIES_SUCCESS: `${prefix}ORDER_GET_ORIGIN_COUNTRIES_SUCCESS`,
  ORDER_GET_ORIGIN_COUNTRIES_FAILURE: `${prefix}ORDER_GET_ORIGIN_COUNTRIES_FAILURE`,
  INIT_ORDER_GET_ORIGIN_COUNTRIES: `${prefix}INIT_ORDER_GET_ORIGIN_COUNTRIES`,

  ORDER_GET_ORIGIN_PORTS: `${prefix}ORDER_GET_ORIGIN_PORTS`,
  ORDER_GET_ORIGIN_PORTS_SUCCESS: `${prefix}ORDER_GET_ORIGIN_PORTS_SUCCESS`,
  ORDER_GET_ORIGIN_PORTS_FAILURE: `${prefix}ORDER_GET_ORIGIN_PORTS_FAILURE`,
  INIT_ORDER_GET_ORIGIN_PORTS: `${prefix}INIT_ORDER_GET_ORIGIN_PORTS`,

  ORDER_GET_DESTINATION_PORTS: `${prefix}ORDER_GET_DESTINATION_PORTS`,
  ORDER_GET_DESTINATION_PORTS_SUCCESS: `${prefix}ORDER_GET_DESTINATION_PORTS_SUCCESS`,
  ORDER_GET_DESTINATION_PORTS_FAILURE: `${prefix}ORDER_GET_DESTINATION_PORTS_FAILURE`,
  INIT_ORDER_GET_DESTINATION_PORTS: `${prefix}INIT_ORDER_GET_DESTINATION_PORTS`,

  ORDER_GET_INLAND_COST: `${prefix}ORDER_GET_INLAND_COST`,
  ORDER_GET_INLAND_COST_SUCCESS: `${prefix}ORDER_GET_INLAND_COST_SUCCESS`,
  ORDER_GET_INLAND_COST_FAILURE: `${prefix}ORDER_GET_INLAND_COST_FAILURE`,
  INIT_ORDER_GET_INLAND_COST: `${prefix}INIT_ORDER_GET_INLAND_COST`,

  ORDER_GET_QUOTATION: `${prefix}ORDER_GET_QUOTATION`,
  ORDER_GET_QUOTATION_SUCCESS: `${prefix}ORDER_GET_QUOTATION_SUCCESS`,
  ORDER_GET_QUOTATION_FAILURE: `${prefix}ORDER_GET_QUOTATION_FAILURE`,
  INIT_ORDER_GET_QUOTATION: `${prefix}INIT_ORDER_GET_QUOTATION`,

  ORDER_GET_QUOTATION_DOWNLOAD_LINK: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK`,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK_SUCCESS`,
  ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE: `${prefix}ORDER_GET_QUOTATION_DOWNLOAD_LINK_FAILURE`,
  INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK: `${prefix}INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK`,

  UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD: `${prefix}UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD`,

  ORDER_GET_SHIP_SCHEDULE: `${prefix}ORDER_GET_SHIP_SCHEDULE`,
  ORDER_GET_SHIP_SCHEDULE_SUCCESS: `${prefix}ORDER_GET_SHIP_SCHEDULE_SUCCESS`,
  ORDER_GET_SHIP_SCHEDULE_FAILURE: `${prefix}ORDER_GET_SHIP_SCHEDULE_FAILURE`,
  INIT_ORDER_GET_SHIP_SCHEDULE: `${prefix}INIT_ORDER_GET_SHIP_SCHEDULE`,

  ORDER_SEND_REQUEST: `${prefix}ORDER_SEND_REQUEST`,
  ORDER_SEND_REQUEST_SUCCESS: `${prefix}ORDER_SEND_REQUEST_SUCCESS`,
  ORDER_SEND_REQUEST_FAILURE: `${prefix}ORDER_SEND_REQUEST_FAILURE`,
  INIT_ORDER_SEND_REQUEST: `${prefix}INIT_ORDER_SEND_REQUEST`,

  ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE: `${prefix}ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE`,
  INIT_ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE: `${prefix}INIT_ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE`,
};

// Actions
export const actions = {
  initOrder: () => {
    return { type: actionType.INIT_ORDER };
  },
  initOrderFromRealtimeService: () => {
    return { type: actionType.INIT_ORDER_FROM_REALTIME_SERVICE };
  },
  updateStep: (nextStep: number) => {
    return { type: actionType.ORDER_UPDATE_STEP, payload: nextStep };
  },
  updateStepOnInputValidation: (step: number) => {
    return {
      type: actionType.ORDER_UPDATE_STEP_ON_INPUT_VALIDATION,
      payload: step,
    };
  },
  updateFormItem: (key: string, value: any) => {
    return { type: actionType.ORDER_UPDATE_FORM_ITEM, payload: { key, value } };
  },

  initGetOriginCountries: () => {
    return { type: actionType.INIT_ORDER_GET_ORIGIN_COUNTRIES };
  },
  getOriginCountries: () => {
    return {
      type: actionType.ORDER_GET_ORIGIN_COUNTRIES,
    };
  },

  initGetDestinationPorts: () => {
    return { type: actionType.INIT_ORDER_GET_DESTINATION_PORTS };
  },
  getDestinationPorts: (originPortId: number) => {
    return {
      type: actionType.ORDER_GET_DESTINATION_PORTS,
      payload: { originPortId },
    };
  },

  initGetInlnadCost: () => {
    return { type: actionType.INIT_ORDER_GET_INLAND_COST };
  },
  getInlandCost: (payload: {
    freightType: CargoTransportType;
    endViaPort: number;
    zoneId: number;
    weight: number;
    containerCBM: number;
    productsInfo: SendRequestPayloadProductsInfoItem[];
  }) => {
    return { type: actionType.ORDER_GET_INLAND_COST, payload };
  },

  initGetQuotation: () => {
    return { type: actionType.INIT_ORDER_GET_QUOTATION };
  },
  getQuotation: (payload: ORDER_GET_QUOTATION_REQ) => {
    return { type: actionType.ORDER_GET_QUOTATION, payload };
  },

  getQuotationDownloadLink: (quotationInfo: ORDER_GET_QUOTATION_RES) => {
    const payload = {
      oceanTicketId: quotationInfo.oceanTicketId,
      quotationInfo,
    };

    return { type: actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK, payload };
  },
  updateQuotationDownloadLinkNeedInitialDownload: (status: BooleanString) => {
    const payload = status;

    return {
      type: actionType.UPDATE_ORDER_GET_QUOTATION_DOWNLOAD_LINK_NEED_INITIAL_DOWNLOAD,
      payload,
    };
  },
  initGetQuotationDownloadLink: () => {
    return { type: actionType.INIT_ORDER_GET_QUOTATION_DOWNLOAD_LINK };
  },

  initSendRequest: () => {
    return { type: actionType.INIT_ORDER_SEND_REQUEST };
  },
  sendRequest: (payload: OceanTicketSendRequestPayload) => {
    return { type: actionType.ORDER_SEND_REQUEST, payload };
  },

  initUpdateStateFromRealtimeService: () => {
    return { type: actionType.INIT_ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE };
  },
  updateStateFromRealtimeService: (
    payload: OceanTicketStateFromRealtimeService
  ) => {
    return {
      type: actionType.ORDER_UPDATE_STATE_FROM_REALTIME_SERVICE,
      payload,
    };
  },
};

// Sagas
const sagas = {
  getOriginCountriesSaga: createRequestSaga(
    actionType.ORDER_GET_ORIGIN_COUNTRIES,
    (payload: any) => {
      return sendRequest({
        method: "get",
        path: "/countries",
        params: {
          isForOceanTicket: true,
        },
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  getDestinationPortsSaga: createRequestSaga(
    actionType.ORDER_GET_DESTINATION_PORTS,
    (payload: any) => {
      return sendRequest({
        method: "get",
        path: "/oceanTicket/port/destination",
        params: {
          startPortId: payload.originPortId,
        },
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  getInlandCostSaga: createRequestSaga(
    actionType.ORDER_GET_INLAND_COST,
    (payload: any) => {
      return sendRequest({
        method: "get",
        path: "/searchEstimate/inlandCost",
        params: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  getQuotationSaga: createRequestSaga(
    actionType.ORDER_GET_QUOTATION,
    (payload: any) => {
      return sendRequest({
        method: "get",
        path: "/searchEstimate/oceanTicket",
        params: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  getQuotationDownloadLinkSaga: createRequestSaga(
    actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK,
    (payload: any) => {
      return sendRequest({
        method: "put",
        path: `/oceanTicket/download/${payload.oceanTicketId}`,
        data: {
          quotationInfoForDownload:
            payload.quotationInfo.quotationInfoForDownload,
        },
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
  sendRequestSaga: createRequestSaga(
    actionType.ORDER_SEND_REQUEST,
    (payload: OceanTicketSendRequestPayload) => {
      return sendRequest({
        method: "post",
        path: "/bid/register",
        data: payload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
};
export function* oceanTicketSaga() {
  yield takeLatest(
    actionType.ORDER_GET_ORIGIN_COUNTRIES,
    sagas.getOriginCountriesSaga
  );
  yield takeLatest(
    actionType.ORDER_GET_DESTINATION_PORTS,
    sagas.getDestinationPortsSaga
  );
  yield takeLatest(actionType.ORDER_GET_INLAND_COST, sagas.getInlandCostSaga);
  yield takeLatest(actionType.ORDER_GET_QUOTATION, sagas.getQuotationSaga);
  yield takeLatest(
    actionType.ORDER_GET_QUOTATION_DOWNLOAD_LINK,
    sagas.getQuotationDownloadLinkSaga
  );
  yield takeLatest(actionType.ORDER_SEND_REQUEST, sagas.sendRequestSaga);
}
