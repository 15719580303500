// Action Type
const prefix = "loading/";
export const actionType = {
  START_LOADING: `${prefix}START_LOADING`,
  FINISH_LOADING: `${prefix}FINISH_LOADING`,
};

// TODO: boful과 합칠 방법 생각해보기
// Actions
export const actions = {
  START_LOADING: (
    requestType: string,
    key?: number
  ): {
    type: string;
    payload?: any;
  } => {
    return {
      type: actionType.START_LOADING,
      payload: { requestType, key },
    };
  },
  FINISH_LOADING: (
    requestType: string,
    key?: number
  ): {
    type: string;
    payload?: any;
  } => {
    return {
      type: actionType.FINISH_LOADING,
      payload: { requestType, key },
    };
  },
};
